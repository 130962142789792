// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_base_url: ''
  // api_base_url: 'https://appbackend.maika.ae/api/v1/',
  api_base_url: 'https://drsunnybackend.paperscissorstone.in/api/v1/',
  // this is for test
  imgUrl: 'https://drsunnybackend.paperscissorstone.in/images/development-milestones/',
  hastagImgUrl: 'https://drsunnybackend.paperscissorstone.in/images/hashtag-moments/',
  // this is for live 
  // imgUrl: 'https://appbackend.maika.ae/images/development-milestones/', 
  // hastagImgUrl: 'https://appbackend.maika.ae/images/hashtag-moments/' ,
  // api_base_url: 'http://192.168.29.105:3002/api/v1/',

  // api_base_url: 'http://192.168.0.103:3001/api/v1/',
  // this is for login session expired
  authModalState: false,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
