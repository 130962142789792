<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
      <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <div class="menu-profile">
          <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
          <span class="name">{{getname}}</span>
        </div>
      </a>
      <div class="dropdown-menu d-flex flex-column align-items-center">
        <div class="dropdown-header align">
          <div class="figure mb-3">
            <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
          </div>
          <div class="info text-center">
            <span class="name" style="white-space: break-spaces;">{{getname}}</span>
            <p class="mb-3 email">{{getRole}}</p>
          </div>
        </div>
        <!-- <div class="dropdown-body">
              <ul class="profile-nav p-0 pt-3">
                  <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
              </ul>
          </div> -->
        <div class="dropdown-footer">
          <ul class="profile-nav">
            <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                  class="logout-text">Logout</span></a></li>
          </ul>
        </div>
  
      </div>
    </div>
    <!-- <p-confirmDialog></p-confirmDialog> -->
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
      <h1>{{ Content.title }}</h1>
      <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{ Content.subTitle }}</li>
        <li class="item">{{ Content.title }}</li>
      </ol>
    </div>
  
    <!-- <div class="add-listings-box">
          <div class="row justify-content-between align-items-center">
              <div class="form-group col-4">
                  <label class="p-0">Name <span class="required">*</span></label>
                  <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
              </div>
              <div class="add-listings-btn mb-0 col-4 text-end">
                  <button type="submit" (click)="addTableData()">Add</button>
              </div>
          </div>
      </div> -->
    <div class="add-listings-box">
      <div class="Master-view-box">
        <div class="theader d-flex  justify-content-between">
          <div class="d-flex  flex-wrap w-100">
            <span class="p-input-icon-left ml-auto mr-2 col-12 col-md-auto mb-2 md-mb-0">
              <i class="pi pi-search"></i>
              <input pInputText type="text" class="form-control "
                  style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                  placeholder="Search Keyword"  (keyup)="applyFilter($any($event.target).value , 'filter')"
                  [(ngModel)]="filterVal" />
  
                   
            </span>
              <p-button type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)"
                tooltipPosition="bottom"></p-button>
            <!-- <p-dropdown #dropdown [ngModel]="dropdownValue"   [options]="options"
            placeholder="Select" [showClear]="false"
           [resetFilterOnHide]="true"  (onChange)="applyFilter($event.value)">
           <ng-template let-option pTemplate="item">
               <span>{{option.label}}</span>
           </ng-template>
           </p-dropdown> -->
  
            <!-- <p-button type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)"
                tooltipPosition="bottom"></p-button>
          </div>
          <div class="add-listings-btn mb-0 ms-2" style="text-align: right" *ngIf="checkPermission(5)">
            <a routerLink="/portal/initial-assessment-form" class="nav-link">
              <button type="submit">Add</button>
            </a>
          </div> -->
        </div>
      </div>
  
      <div class="row mt-4">
        <div class="col-lg-12 col-md-12">
          <p-table #dt1 [value]="auditlogs" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
              [rowsPerPageOptions]="[10, 25, 50,100]" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [loading]="loading" [paginator]="true"
              currentPageReportTemplate="Showing {first} to
                      {last} of {totalRecords} entries" [globalFilterFields]="['created_at', 'action', 'model', 'updated_at','user_id','user_name','name']"
              editMode="row">
            <ng-template pTemplate="header">
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Patient Name / Action</th>
                <th>Model</th>
                <th>Action By</th>
                <th>User name</th>
              
              </tr>
            </ng-template>
            <!-- table to show data -->
            <ng-template pTemplate="body" let-list let-i="rowIndex">
              <tr>
                <td>{{ list?.created_at ?? '-'| date : 'dd/MM/yyyy' }}</td>
                <td>{{ list.created_at | date:'hh:mm:ss a' || "-" }}</td>
                <td>{{ list?.action ?? "-" | titlecase }}</td>
                <td>{{ list?.model ?? "-" }}</td>
                <td>{{ list?.name ?? "-" }}</td>
                <td>{{ list?.user_name }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No Data Found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
  
        <!----------------------------------------------------------- view dialog --------------------------------------------------------->
        <!-- ***************************************************************************************************************** -->
      
      </div>
    </div>
    <app-copyrights></app-copyrights>
  </div>