import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { async } from 'rxjs/internal/scheduler/async';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilityTsService {
  title: any;
  api: any = [];
  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
  // Get Nanny plan
  getNannyPlan() {
    return this.http.get(this.apiBaseUrl + 'get-all-nannyplan');
  }
  async getData(plan: string) {
    try {
      const res: any = await this.getNannyPlan().toPromise();
      console.log(res.data, 'dataaaaa');
      this.title = res?.data?.filter((item: any) => item.heading_name === plan);
      console.log('gettttt', this.title);

      return this.title[0]?.id;
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error as needed
    }
  }
  public parseISOString(s: string): Date | null {
    if (!s) return null;
    return new Date(s);
  }
  processFormData(formData: any): any {
    const jsonObject: { [key: string]: any } = JSON.parse(JSON.stringify(formData));
    const newObject = JSON.parse(JSON.stringify(jsonObject));
    console.log("before modificatiom ", jsonObject);

    for (const key in newObject) {
      const value = newObject[key];
      debugger
      if (Array.isArray(value)) {
        for (const arrayKey of value) {
          let targetKeyExist = false;
          let nurseIdEmpty = false;

          for (const [itemKey, itemValue] of Object.entries(arrayKey)) {
            if (
              (itemKey.includes("actual_time") && itemValue != null && itemValue !== "") ||
              (itemKey.includes("remark") && itemValue != null && itemValue !== "") ||
              (itemKey.includes("vitamin_remark") && itemValue != null && itemValue !== "") ||
              (itemKey.includes("diaperRemark") && itemValue != null && itemValue !== "") ||
              (itemKey.includes("sleeping_time") && itemValue != null && itemValue !== "") ||
              (itemKey.includes("skin_care_specify_produ") && itemValue != null && itemValue !== "")
            ) {
              targetKeyExist = true;
            }

            if (itemKey === "nurses" && (itemValue == null || itemValue === "")) {
              nurseIdEmpty = true;
            }

            if (Array.isArray(itemValue)) {
              for (const arrayKey2 of itemValue) {
                let targetKeyExist2 = false;
                let nurseIdEmpty2 = false;

                for (const [itemKey2, itemValue2] of Object.entries(arrayKey2)) {
                  if (
                    (itemKey2.includes("actual_time") && itemValue2 != null && itemValue2 !== "") ||
                    (itemKey2.includes("remark") && itemValue2 != null && itemValue2 !== "") ||
                    (itemKey2.includes("vitamin_remark") && itemValue2 != null && itemValue2 !== "") ||
                    (itemKey2.includes("diaperRemark") && itemValue2 != null && itemValue2 !== "") ||
                    (itemKey2.includes("sleeping_time") && itemValue2 != null && itemValue2 !== "") ||
                    (itemKey2.includes("skin_care_specify_produ") && itemValue2 != null && itemValue2 !== "")
                  ) {
                    targetKeyExist2 = true;
                  }

                  if (itemKey2 === "nurses" && (itemValue2 == null || itemValue2 == "")) {
                    console.log("nitesh", itemKey2, itemValue2);

                    nurseIdEmpty2 = true;
                  }
                }

                if (targetKeyExist2 && nurseIdEmpty2) {
                  const newNurseId = localStorage.getItem('name');
                  arrayKey2["nurses"] = newNurseId;
                }
              }
            }
          }

          if (targetKeyExist && nurseIdEmpty) {
            const newNurseId = localStorage.getItem('name');
            arrayKey["nurses"] = newNurseId;
          }
        }
      }

    }

    console.log("after modification ", newObject);


    return newObject;
  }

}
