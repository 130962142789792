import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { MasterService } from 'src/app/services/master.service';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonApiService } from 'src/app/services/common-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Dropdown } from 'primeng/dropdown';
@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Audit Log History',
      subTitle: 'Dashboard'
    }
  ];

  @ViewChild('dt1') dataTable!: Table;
  @ViewChild('dropdown') dropdown!: Dropdown;

  getname: any;
  getRole: any;
  getToken: any;
  filterVal: any;
  day: number;
  month: number;
  year: number;
  nurseLogin: boolean = false;
  headNurse: boolean = false;
  superAdmin: boolean = false;
  loading: boolean = false;
  date: any = new Date('2000-01-04T10:04:04.000Z');
  data: any;
  contract: any = [];
  sibling: any = [];
  relatives: any = [];
  nurse: any;
  displayMaximizable: boolean = false;
  viewDetail: any = [];
  auditlogs: any = [];
  permissionData: any = [];
  permissions: any = [];
  admin: boolean = false;
  tooltipText: string = 'Baby Child Registration';
  filterValues: any[] = [];
  pageLength: number = 0;
  limit: number = 10; // Default value
  offset: number = 0;
  initialLoad: boolean = true;
  dropdownValue: any;
  totalRecords:any;
  totalPages:any;
  options: any = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  constructor(
    private initial_ass_service: MaikaListService,
    private common_service: CommonApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private masterService: MasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('babyId');
    localStorage.removeItem('editedOrderId');
    localStorage.removeItem('create');
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role');
    this.applyFilter('');

  }

  ngAfterViewInit(): void {
    this.dataTable.onPage.subscribe((event) => {
      this.limit = event.rows;
      this.offset = event.first;
      this.pageLength = event.page;
      this.applyFilter(this.filterVal)
    });

    // Trigger the paginator event programmatically on initialization
    this.dataTable.reset();
  }

  async applyFilter(filterValue: string, filter?: any) {
    console.log("Filter function called with value:", filterValue);
    if (filter) {
      this.pageLength = 0;
      this.filterValues = [filterValue];
      console.log(this.filterValues,"value");
      
    }
  
    try {
      console.log("Calling API with:", this.filterValues, this.limit, this.offset);
  
      // Use .toPromise() to convert Observable to Promise
      const res: any = await this.common_service.getauditlog(this.limit, this.offset, this.filterValues[0]).toPromise();
  
     
      if (res.code === 200) {
        this.auditlogs = res.data.logs; // Ensure this is an array
        this.totalRecords = res.data.total;
        console.log(this.auditlogs,"error");
        console.log(this.totalRecords,"record");
        
      } else {
        this.auditlogs = [];
      }
    } catch (error) {
      console.error('Error fetching data', error);
      this.data = [];
    }
  }
 

  reset(dt: Table) {
    dt.reset();
    this.dropdown?.clear(this.filterVal);
    this.filterVal = '';
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
}
