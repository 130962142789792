import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonApiService } from 'src/app/services/common-api.service';
import { UtilityTsService } from 'src/app/services/utility.ts.service';
import { MasterService } from 'src/app/services/master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api'; // Ensure this import
import { valueOrDefault } from 'chart.js/dist/helpers/helpers.core';

@Component({
  selector: 'app-edit-one-year-to-three-year',
  templateUrl: './edit-one-year-to-three-year.component.html',
  styleUrls: ['./edit-one-year-to-three-year.component.scss'],
  providers: [MessageService],
})
export class EditOneYearToThreeYearComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Edit Nurse CARE PLAN 1 YEAR - 3 YEARS',
      subTitle: 'Dashboard',
    },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  public flag: boolean = true;
  planId: any;
  public playWith: boolean = true;
  public spongeBath: boolean = true;
  public tubBath: boolean = true;
  public escort: boolean = true;
  showNeedsTextarea: boolean = false;
  formula: boolean = false;
  selectedDate: Date = null; // Initialize with today's date
  wipes: boolean = true;
  years: any = '';
  Breast: boolean = true;
  Formula: boolean = true;
  All: boolean = true;
  One: boolean = true;
  Snack: boolean = true;
  Yes: boolean = true;
  sleep_training: boolean = true;
  No: boolean = true;
  Breakfast: boolean = true;
  Lunch: boolean = true;
  Dinner: boolean = true;
  Shower: boolean = true;
  Sponge: boolean = true;
  With_Nanny: boolean = true;
  With_Mother: boolean = true;
  Tub: boolean = true;
  month: any = '';
  days: any = '';
  selectedNumber: any;
  ageList: any = [];
  babyValues: any = [];
  babyGender: any;
  babyDOB: any;
  name: any;
  FirstEntry: any;
  isFormula: any = false;
  isBathingtub: any = false;
  isBathingshower: any = false;
  isSettingValueProgrammatically = true;
  ismedicine: boolean = false;
  isBreast: boolean = false;
  isdiaper:boolean=false;
  permissions: any = [];
  permissionData: any = [];
  subscription: Subscription;
  frequencyList: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
  ];
  FormulaValue: any = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },
  ];

  oneToThreeYear = this.FB.group({
    baby_details_id: new FormControl(null),

    feeding_prepared_mother: new FormControl(null),
    feeding_prepared_other: new FormControl(null),
    feeding_prepared_nanny: new FormControl(null),
    feeding_type: new FormControl(null),
    feeding_breast: new FormControl(null),
    feeding_frequency_breast: new FormControl(null),
    feeding_breast_time: new FormControl(null),
    feeding_time: new FormControl(null),
    feeding_formula_time_end: new FormControl(null),
    feeding_frequency: new FormControl(null),
    feeding_amount_per: new FormControl(null),
    type_of_formula: new FormControl(null),
    bottle_sterilization_all: new FormControl(null),
    bottle_sterilization_onetime: new FormControl(null),
    formula_calculation: this.FB.array([]),
    bottle_sterilization: this.FB.array([]),
    diaper_changes: this.FB.array([]),
    solid_other_snack: new FormControl(null),
    solid_other_snack_time: new FormControl(null),
    soild_other_snack_food_components: new FormControl(null),
    solid_other_snack_actual_time: new FormControl(null),
    // regular_meal_type: new FormControl(null),
    // regular_meal_time: new FormControl(null),
    // regular_meal_food_components: new FormControl(null),
    regular_meal_lunch: new FormControl(null),
    regular_meal_lunch_time: new FormControl(null),
    regular_meal_lunch_food_components: new FormControl(null),
    regular_meal_dinner: new FormControl(null),
    regular_meal_dinner_time: new FormControl(null),
    regular_meal_dinner_food_components: new FormControl(null),
    all_time: new FormControl(null),
    onetime: new FormControl(null),
    food_medicine_allergy: new FormControl(null),
    // food_medicine_allergy_no: new FormControl(''),
    food_medicine_allergy_specify_prod: new FormControl({
      value: null,
      disabled: true,
    }),
    // bathing_type: new FormControl(''),
    // bathing_frequency: new FormControl(''),
    // bathing_time: new FormControl(''),
    bathing_tub: new FormControl(null),
    bathing_tub_frequency: new FormControl(null),
    bathing_tub_time: new FormControl(null),
    bathing_shower: new FormControl(null),
    bathing_shower_frequency: new FormControl(null),
    bathing_shower_time: new FormControl(null),
    skin_care_lotion: new FormControl(null),
    skin_care_baby_oil: new FormControl(null),
    skin_care_others: new FormControl(null),
    skin_care_specify_produ: new FormControl(null),
    skin_care_Wipes: new FormControl(null),
    // play_child_outside: new FormControl(null),
    sleeping_independently: new FormControl(null),
    // play_child_outside_special_Instructions: new FormControl(null),
    // escort_child_outside: new FormControl(null),
    // escort_child_outside_special_Instructions: new FormControl(null),
    skin_care_special_instructions: new FormControl(),
    changing_colthes_frequency: new FormControl(null),
    changing_colthes_spiecal_instructions: new FormControl(null),
    sleeping_mother: new FormControl(null),
    sleeping_mother_time: new FormControl(null),
    sleeping_nanny: new FormControl(null),
    sleeping_nanny_time: new FormControl(null),
    temperature_monitoring_frequency: new FormControl(null),
    diaper_change_frequency: new FormControl(null),
    diaper_change_times: this.FB.array([]),
    vitamins_medications_json: this.FB.array([]),
    vaccinations_json: this.FB.array([]),
    solid_food: this.FB.array([]),
    lunch_food: this.FB.array([]),
    dinner_food: this.FB.array([]),
    outside_child: this.FB.array([]),
    escort_child: this.FB.array([]),
    activities_name: this.FB.array([]),
    potty_training: this.FB.array([]),
    sleeping_training: this.FB.array([]),
    skin_care: this.FB.array([]),
    tub_bathing: this.FB.array([]),
    shower_bathing: this.FB.array([]),
    remarks: new FormControl(null),
    breast_feeding: this.FB.array([]),
    changing_clothes: this.FB.array([]),
    sleeping_time: this.FB.array([]),
    nanny_time: this.FB.array([]),
    nurse_remarks: this.FB.array([]),
    diaper_calculation:this.FB.array([]),
    diaper_label_end_time:new FormControl(null),
    diaper_label:new FormControl (null),
    done_sterilization_onetime:new FormControl(null),
    onetime_Remark: new FormControl (null),
    actual_sterilization_all_time:new FormControl(null),
  });
  isedit: boolean;
  control: any;
  // genderArray: any = [
  //   { name: 'Male', value: 'male' },
  //   { name: 'Female', value: 'female' },
  // ];
  constructor(
    private router: Router,
    private commonService: CommonApiService,
    private FB: FormBuilder,
    private messageService: MessageService,
    private utility_service: UtilityTsService,
    private spinner: NgxSpinnerService,
    private masterService: MasterService,
    private confirmationService: ConfirmationService,
  ) {
    this.getAllUserPermission()
  }
  nannyTaskOnFinalSubmit() {
    // Show confirmation dialog
    this.oneToThreeYear.enable();
    let structuredObject = {
      baby_details_id: this.oneToThreeYear.get('baby_details_id').value,
      remarks:this.oneToThreeYear.get('remarks').value,
      nurse_remarks:this.oneToThreeYear.get('nurse_remarks').value,
      bathing:[
        {
          bathing_tub:this.oneToThreeYear.get('bathing_tub').value,
          bathing_tub_frequency:this.oneToThreeYear.get('bathing_tub_frequency').value,
          tub_bathing:this.oneToThreeYear.get('tub_bathing').value,
          bathing_shower:this.oneToThreeYear.get('bathing_shower').value,
          bathing_shower_frequency:this.oneToThreeYear.get('bathing_shower_frequency').value,
          shower_bathing:this.oneToThreeYear.get('shower_bathing').value,
        }
      ],
      bottle_sterilization:[
        {
          bottle_sterilization:this.oneToThreeYear.get('bottle_sterilization').value,
          bottle_sterilization_all:this.oneToThreeYear.get('bottle_sterilization_all').value,
          all_time:this.oneToThreeYear.get('all_time').value,
          onetime:this.oneToThreeYear.get('onetime').value,
          done_sterilization_onetime:this.oneToThreeYear.get('done_sterilization_onetime').value,
          actual_sterilization_all_time:this.oneToThreeYear.get('actual_sterilization_all_time').value,
          onetime_Remark:this.oneToThreeYear.get('onetime_Remark').value,
        }
      ],
       clothes:[
        {
        changing_clothes:this.oneToThreeYear.get('changing_clothes').value,
        changing_colthes_spiecal_instructions:this.oneToThreeYear.get('changing_colthes_spiecal_instructions').value,
       }
      ],
      diaper:[{
        diaper_calculation:this.oneToThreeYear.get('diaper_calculation').value,
        diaper_change_frequency:this.oneToThreeYear.get('diaper_change_frequency').value,
        diaper_label:this.oneToThreeYear.get('diaper_label').value,
        diaper_label_end_time:this.oneToThreeYear.get('diaper_label_end_time').value,
        diaper_change_times:this.oneToThreeYear.get('diaper_change_times').value
      }],
      
      breastfeeding:[
        {
         feeding_frequency_breast:this.oneToThreeYear.get('feeding_frequency_breast').value,
         breast_feeding:this.oneToThreeYear.get('breast_feeding').value,
        }
      ],
      formula_feeding:[
        {
         feeding_frequency:this.oneToThreeYear.get('feeding_frequency').value,
         feeding_formula_time_end:this.oneToThreeYear.get('feeding_formula_time_end').value,
          feeding_time:this.oneToThreeYear.get('feeding_time').value,
          feeding_amount_per:this.oneToThreeYear.get('feeding_amount_per').value,
          type_of_formula:this.oneToThreeYear.get('type_of_formula').value,
             feeding_type: this.oneToThreeYear.get('feeding_type').value,
             formula_calculation:this.oneToThreeYear.get('formula_calculation').value,
        }
      ],
      prepared:[
        {
          feeding_prepared_mother:this.oneToThreeYear.get('feeding_prepared_mother').value,
          feeding_prepared_nanny:this.oneToThreeYear.get('feeding_prepared_nanny').value,
           feeding_prepared_other: this.oneToThreeYear.get('feeding_prepared_other').value,
        }
      ],
      sleeping:[{
        sleeping_time:this.oneToThreeYear.get('sleeping_time').value,
        nanny_time:this.oneToThreeYear.get('nanny_time').value,
      }],
      skin:[{
        skin_care:this.oneToThreeYear.get('skin_care').value,
      }],
      vitamin:[
        {
          vitamins_medications_json:this.oneToThreeYear.get('vitamins_medications_json').value,
        }
      ],
      wipes:[
        {
          skin_care_special_instructions:this.oneToThreeYear.get('skin_care_special_instructions').value,
          skin_care_Wipes:this.oneToThreeYear.get('skin_care_Wipes').value,
        }
      ],
      food:[{
        solid_food:this.oneToThreeYear.get('solid_food').value,
        lunch_food:this.oneToThreeYear.get('lunch_food').value,
        dinner_food:this.oneToThreeYear.get('dinner_food').value,
        solid_other_snack_time:this.oneToThreeYear.get('solid_other_snack_time').value,
        soild_other_snack_food_components:this.oneToThreeYear.get('soild_other_snack_food_components').value,
      }],
      allergy:[
        {
          food_medicine_allergy:this.oneToThreeYear.get('food_medicine_allergy').value,
          food_medicine_allergy_specify_prod:this.oneToThreeYear.get('food_medicine_allergy_specify_prod').value,
        }
      ],
      sleep_training:[
        {
          sleeping_training:this.oneToThreeYear.get('sleeping_training').value,
        }
      ],
      potty_training:[
        {
          potty_training:this.oneToThreeYear.get('potty_training').value,
        }
      ],
      activities:[
        {
          outside_child:this.oneToThreeYear.get('outside_child').value,
          escort_child:this.oneToThreeYear.get('escort_child').value,
          activities_name:this.oneToThreeYear.get('activities_name').value,
        }
      ]

  };
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to submit this task?',
      header: 'Submit Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-success p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        // If user confirms, proceed with submission
        let id = localStorage.getItem('babyId');
        let nurseid=  localStorage.getItem('nurseTaskEditID') 
       
      let payload = {
        nanny_task_details_json: JSON.stringify(this.oneToThreeYear.value),
        structured_json: JSON.stringify(structuredObject),
        task_id :localStorage.getItem('nurseTaskEditID'),
        baby_id:localStorage.getItem('babyId'),
        baby_plan_id: 3,
      };
      const jsonString: string = JSON.stringify(this.oneToThreeYear.value);
        // Correctly call the method from the service
        const processedData = this.utility_service.processFormData(JSON.parse(jsonString));
        console.log("Processed Data: ", processedData);
      
      


      payload.nanny_task_details_json = JSON.stringify(processedData)
        this.spinner.show();
        this.commonService.nannyfinaltasksubmit(id,payload)
          .then((res: any) => {
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 2000);
          })
          .catch((error: any) => {
            this.spinner.hide();
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
            });
          });
      },
      reject: () => {
        // If user rejects, show rejection message
        this.messageService.add({
          severity: 'info',
          summary: 'Rejected',
          detail: 'Submission cancelled',
        });
      }
    });
  }
  ngOnInit(): void {
    this.getBabyDetails();
    if (localStorage.getItem('nurseTaskEditID')) {
      this.getEditFormData();
      this.isedit = true;
      // this.addVitaminObject('add', 0);
    }
    else {
      this.isedit = false;
      this.getCreateFormData();
      // this.addVitaminObject('add', 0);
      // this.addVaccinationObject('add', 0);
      // this.addRegularObject('add', 0);
      // this.addLunchObject('add', 0);
      // this.addDinnerObject('add', 0);
      // this.addPlayObject('add', 0);
      // this.addEscortObject('add', 0);
      // this.addActiveObject('add', 0);
      // this.addTrainingObject('add', 0);
      // this.addSleepTrainingObject('add', 0);
      // this.addSkinObject('add', 0);
    }
    // this.addNurseRemark('add', 0);
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
  getNurseRemarks() {
    return this.oneToThreeYear.get('nurse_remarks') as FormArray;
  }

  addNurseRemark(string: any, index: any) {
    let remarkArray = this.getNurseRemarks();
    const currentDate = new Date();

    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) {
      hours -= 12;
    }

    if (string == 'add') {
      let newremarkArray = new FormGroup({
        nurse_remark: new FormControl(''),
        nurse_remark_time: new FormControl(`${hours}:${minutes}:${seconds} ${amOrPm}`),
        nurses:new FormControl(null)
      });
      remarkArray.insert(index + 1, newremarkArray);
    } else {
      remarkArray.removeAt(index);
    }
  }

  CheckFormula(event: any, controls) {
    if (event == 'Yes') {
      controls.get('remark').disable();
    }
    else {
      controls.get('remark').enable();
    }
  }
  getCreateFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('babyId');
    this.commonService.getEditNursetaskByBaby(getEdit).then((res: any) => {
      this.spinner.hide()
      const detailsInGrowthMonitoring = res.data[0];
      const nannytask = detailsInGrowthMonitoring.nanny_task_details_json;
  
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
     
      //store value of all json in a variable
      const VitaminsMedication =
        res.data[0].nanny_task_details_json.vitamins_medications_json;
      const vaccine = res.data[0].nanny_task_details_json.vaccinations_json;
      const regular = res.data[0].nanny_task_details_json.solid_food;
      const lunch = res.data[0].nanny_task_details_json.lunch_food;
      const dinner = res.data[0].nanny_task_details_json.dinner_food;
      const training = res.data[0].nanny_task_details_json.potty_training;
      const sleeping = res.data[0].nanny_task_details_json.sleeping_training;
      const playing = res.data[0].nanny_task_details_json.outside_child;
      const escort = res.data[0].nanny_task_details_json.escort_child;
      const activity = res.data[0].nanny_task_details_json.activities_name;
      const bottle = res.data[0].nanny_task_details_json.bottle_sterilization;
      const formula = res.data[0].nanny_task_details_json.formula_calculation;
      const skin = res.data[0].nanny_task_details_json.skin_care;
      const nurseRemarks = res.data[0].nanny_task_details_json.nurse_remarks;
      this.oneToThreeYear.controls.baby_details_id.setValue(
        JSON.parse(detailsInGrowthMonitoring.baby_details_id)
      );
      this.oneToThreeYear.controls.feeding_frequency.setValue(
        detailsInGrowthMonitoring.nanny_task_details_json.feeding_frequency
      );
    
      //set values of all formcontrols in nanny task
      this.oneToThreeYear.controls.feeding_prepared_mother.setValue(
        nannytask.feeding_prepared_mother
      );
      this.oneToThreeYear.controls.feeding_prepared_other.setValue(
        nannytask.feeding_prepared_other
      );
      this.oneToThreeYear.controls.feeding_prepared_nanny.setValue(
        nannytask.feeding_prepared_nanny
      );
      this.oneToThreeYear.controls.feeding_type.setValue(
        nannytask.feeding_type
      );
      this.oneToThreeYear.controls.feeding_breast.setValue(
        nannytask.feeding_breast
      );
      this.oneToThreeYear.controls.solid_other_snack.setValue(
        nannytask.solid_other_snack
      );
      this.oneToThreeYear.controls.regular_meal_dinner.setValue(
        nannytask.regular_meal_dinner
      );
      this.oneToThreeYear.controls.regular_meal_lunch.setValue(
        nannytask.regular_meal_lunch
      );
      this.oneToThreeYear.controls.bathing_tub.setValue(nannytask.bathing_tub);
      this.oneToThreeYear.controls.bathing_shower.setValue(
        nannytask.bathing_shower
      );
      this.oneToThreeYear.controls.sleeping_mother.setValue(
        nannytask.sleeping_mother
      );
      this.oneToThreeYear.controls.sleeping_nanny.setValue(
        nannytask.sleeping_nanny
      );

      this.oneToThreeYear.controls.temperature_monitoring_frequency.setValue(
        nannytask.temperature_monitoring_frequency
      );
      this.oneToThreeYear.controls.sleeping_independently.setValue(
        nannytask.sleeping_independently
      );
      this.oneToThreeYear.controls.food_medicine_allergy.setValue(
        nannytask.food_medicine_allergy
      );
      this.oneToThreeYear.controls.diaper_change_frequency.setValue(
        nannytask.diaper_change_frequency
      );
      this.oneToThreeYear.controls.diaper_change_frequency.setValue(nannytask.diaper_change_frequency);
      this.oneToThreeYear.controls.diaper_label.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label)
      );
      this.oneToThreeYear.controls.diaper_label_end_time.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label_end_time)
      );
      //diaper frequency set value

      const adddiaper = res.data[0].nanny_task_details_json.diaper_calculation;
      for (let i = 0; i < adddiaper?.length; i++) {
        this.isdiaper = true;
        let bottleArray = this.getdiaperCalculationControls();
        let bottleFromApi = adddiaper[i] || {};

        let newbottleArray = new FormGroup({
          diaper_timeArray: new FormControl(bottleFromApi.diaper_timeArray ? new Date(bottleFromApi.diaper_timeArray)
              : null),
          diaper_actual_time: new FormControl(
            bottleFromApi.diaper_actual_time
              ? new Date(bottleFromApi.diaper_actual_time)
              : null
          ),
          remark: new FormControl(bottleFromApi.remark || ''),
          amount: new FormControl(bottleFromApi.amount || ''),
          diaperRemark: new FormControl(bottleFromApi.diaperRemark || ''),
          diaper_checkbox: new FormControl(bottleFromApi.diaper_checkbox || ''),
          nurses: new FormControl(bottleFromApi.nurses||null)
        });

        bottleArray.insert(i, newbottleArray);
      }


      // if (nurseRemarks != undefined) {
      //   for (let i = 0; i < nurseRemarks?.length; i++) {
      //     this.isBathingtub = true;
      //     let CalculationArray = this.getNurseRemarks();
      //     let time = nurseRemarks[i] || {};

      //     const newCalculationGroup = this.FB.group({
      //       nurse_remark: new FormControl(time.nurse_remark ? time.nurse_remark : null),
      //       nurse_remark_time: new FormControl(time.nurse_remark_time ? time.nurse_remark_time : null)

      //     });
      //     CalculationArray.push(newCalculationGroup);
      //   }
      // }
      // else {
      //   this.addNurseRemark('add', 0);
      // }
      // changing_clothes
      const changingclothes =
        res.data[0].nanny_task_details_json.changing_clothes;
      for (let i = 0; i < changingclothes?.length; i++) {
        let clothesarray = this.getClothesFormArray();
        let clothes = changingclothes[i] || {};

        const newclothesGroup = this.FB.group({
          changing_colthes: new FormControl(clothes.changing_colthes || ''),
          Changing_time: new FormControl(
            clothes.Changing_time
              ? new Date(clothes.Changing_time)
              : null
          ),
          Changing_actual_time: new FormControl(
            clothes.Changing_actual_time
              ? new Date(clothes.Changing_actual_time)
              : null
          ),
          clothe_remark: new FormControl(clothes.clothe_remark || ''),
          nurses: new FormControl(clothes.nurses||null)

        });
        clothesarray.push(newclothesGroup);
      }

      // set nurse Remarks
      for (let i = 0; i < nurseRemarks?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getNurseRemarks();
        let time = nurseRemarks[i] || {};

        const newCalculationGroup = this.FB.group({
          nurse_remark: new FormControl(time.nurse_remark ? time.nurse_remark : null),
          nurse_remark_time: new FormControl(time.nurse_remark_time ? time.nurse_remark_time : null),
          nurses: new FormControl(time.nurses||null)

        });
        CalculationArray.push(newCalculationGroup);
      }
      // set value of diaper changes
      const diapherchange =
        res.data[0].nanny_task_details_json.diaper_change_times;
      for (let i = 0; i < diapherchange?.length; i++) {
        let CalculationArray = this.getDiaperChangeControls();
        let diaper = diapherchange[i] || {};

        const newCalculationGroup = this.FB.group({
          change: new FormControl(diaper.change || ''),
          time: new FormControl(
            diaper.time
              ? new Date(diaper.time)
              : null
          ),
          diaper_remark: new FormControl(diaper.diaper_remark || ''),
          nurses: new FormControl(diaper.nurses||null)

        });
        CalculationArray.push(newCalculationGroup);
      }
      // Assuming nannytask has a property named 'formula_calculation' which is an array
      // and you want to set the 'remark' value for the first item in the array
      if (
        nannytask.formula_calculation &&
        nannytask.formula_calculation?.length > 0
      ) {
        const firstFormulaCalculation = nannytask.formula_calculation[0]; // Adjust the index as needed
        const formulaControls = this.oneToThreeYear.get(
          'formula_calculation'
        ) as FormArray;

        if (formulaControls && formulaControls?.length > 0) {
          formulaControls
            .at(0)
            .get('remark')
            .setValue(firstFormulaCalculation.remark);
        }
      }

      //set value of vitamin json in nanny task
      for (let i = 0; i < VitaminsMedication?.length; i++) {
        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};

        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin || ''),
          medicine_time: new FormArray([]),
          frequency: new FormControl(VitaminsMedicationFromApi.frequency || ''),
          nurses: new FormControl(VitaminsMedicationFromApi.nurses||null)

        });

        vitaminArray.insert(i, newVitaminArray);

        this.ismedicine = true;

        for (let j = 0; j < VitaminsMedicationFromApi.medicine_time?.length; j++) {

          const viamincalc = (this.oneToThreeYear.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray;
          const bathingcalculationgroup = this.FB.group({
            time: new FormControl(new Date(VitaminsMedicationFromApi.medicine_time[j].time)),
            vitamin_checkbox: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_checkbox),
            vitamin_remark: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_remark),
            actual_time: new FormControl(
              VitaminsMedicationFromApi.medicine_time[j].actual_time
                ? new Date(VitaminsMedicationFromApi.medicine_time[j].actual_time)
                : null
            ),
            nurses: new FormControl(VitaminsMedicationFromApi.medicine_time[j].nurses)

          });
          viamincalc.push(bathingcalculationgroup);

        }
      }
      //set value of vaccine json in nanny task
      for (let i = 0; i < vaccine?.length; i++) {
        let vaccinationArray = this.getVaccinationFormArray();
        let vaccineApi = vaccine[i] || {};

        let newVaccinationArray = new FormGroup({
          vaccination: new FormControl(vaccineApi.vaccination || ''),
          date: new FormControl(
            vaccineApi.date ? new Date(vaccineApi.date) : null
          ),
        });

        vaccinationArray.insert(i, newVaccinationArray);
      }
      //set value of regular food json in nanny task
      for (let i = 0; i < regular?.length; i++) {
        let RegularArray = this.getRegularFormArray();
        let regularApi = regular[i] || {};

        let newRegularArray = new FormGroup({
          regular_meal_type: new FormControl(
            regularApi.regular_meal_type || ''
          ),
          regular_meal_time: new FormControl(
            regularApi.regular_meal_time
              ? new Date(regularApi.regular_meal_time)
              : null
          ),
          regular_meal_actual_time: new FormControl(
            regularApi.regular_meal_actual_time
              ? new Date(regularApi.regular_meal_actual_time)
              : null
          ),

          regular_meal_food_components: new FormControl(
            regularApi.regular_meal_food_components || ''
          ),
          nurses: new FormControl(regularApi.nurses||null)

        });

        RegularArray.insert(i, newRegularArray);
      }
      //set value of lunch json in nanny task
      for (let i = 0; i < lunch?.length; i++) {
        let LunchArray = this.getLunchFormArray();
        let lunchApi = lunch[i] || {};

        let newLunchArray = new FormGroup({
          regular_meal_lunch: new FormControl(
            lunchApi.regular_meal_lunch || ''
          ),
          regular_meal_lunch_time: new FormControl(
            lunchApi.regular_meal_lunch_time
              ? new Date(lunchApi.regular_meal_lunch_time)
              : null
          ),
          regular_meal_lunch_actual_time: new FormControl(
            lunchApi.regular_meal_lunch_actual_time
              ? new Date(lunchApi.regular_meal_lunch_actual_time)
              : null
          ),
          regular_meal_lunch_food_components: new FormControl(
            lunchApi.regular_meal_lunch_food_components || ''
          ),
          nurses: new FormControl(lunchApi.nurses||null)

        });

        LunchArray.insert(i, newLunchArray);
      }
      //set value of dinner json in nanny task
      for (let i = 0; i < dinner?.length; i++) {
        let dinnerArray = this.getDinnerFormArray();
        let dinnerApi = dinner[i] || {};

        let newdinnerArray = new FormGroup({
          regular_meal_dinner: new FormControl(
            dinnerApi.regular_meal_dinner || ''
          ),
          regular_meal_dinner_time: new FormControl(
            dinnerApi.regular_meal_dinner_time
              ? new Date(dinnerApi.regular_meal_dinner_time)
              : null
          ),
          regular_meal_dinner_actual_time: new FormControl(
            dinnerApi.regular_meal_dinner_actual_time
              ? new Date(dinnerApi.regular_meal_dinner_actual_time)
              : null
          ),
          regular_meal_dinner_food_components: new FormControl(
            dinnerApi.regular_meal_dinner_food_components || ''
          ),
          nurses: new FormControl(dinnerApi.nurses||null)

        });

        dinnerArray.insert(i, newdinnerArray);
      }
      //set value of training json in nanny task
      for (let i = 0; i < training?.length; i++) {
        let TrainingArray = this.getTrainingFormArray();
        let trainingApi = training[i] || {};

        let newTrainingArray = new FormGroup({
          potty_training: new FormControl(trainingApi.potty_training || ''),
          training_child: new FormControl(trainingApi.training_child || ''),
          training_child_remark: new FormControl(trainingApi.training_child_remark || ''),
          done_training_child: new FormControl(trainingApi.done_training_child || ''),
          time_training_child: new FormControl(
            trainingApi.time_training_child
              ? new Date(trainingApi.time_training_child)
              : null
          ),
          time_training_actual_child: new FormControl(
            trainingApi.time_training_actual_child
              ? new Date(trainingApi.time_training_actual_child)
              : null
          ),
          nurses: new FormControl(trainingApi.nurses||null)

        });

        TrainingArray.insert(i, newTrainingArray);
      }
      //set value of sleep training json in nanny task
      for (let i = 0; i < sleeping?.length; i++) {
        let SleepingArray = this.getsleepTrainingFormArray();
        let NewSleepingApi = sleeping[i] || {};

        let newTrainingArray = new FormGroup({
          sleeping_time: new FormControl(
            NewSleepingApi.sleeping_time
              ? new Date(NewSleepingApi.sleeping_time)
              : null
          ),
          nurses: new FormControl(NewSleepingApi.nurses||null)

        });

        SleepingArray.insert(i, newTrainingArray);
      }
      //set value of play outside json in nanny task
      for (let i = 0; i < playing?.length; i++) {
        let playingArray = this.getPlayFormArray();
        let playApi = playing[i] || {};

        let newpalyingArray = new FormGroup({
          play_child_outside: new FormControl(playApi.play_child_outside || ''),
          play_child_outside_special_Instructions: new FormControl(
            playApi.play_child_outside_special_Instructions || ''
          ),
          play_child_outside_done: new FormControl(playApi.play_child_outside_done || ''),
          play_child_outside_time: new FormControl(
            playApi.play_child_outside_time
              ? new Date(playApi.play_child_outside_time)
              : null
          ),
          play_child_outside_actual_time: new FormControl(
            playApi.play_child_outside_actual_time
              ? new Date(playApi.play_child_outside_actual_time)
              : null
          ),
          play_child_outside_remark: new FormControl(playApi.play_child_outside_remark || ''),
          nurses: new FormControl(playApi.nurses||null)

        });

        playingArray.insert(i, newpalyingArray);
      }
      //set value of escort json in nanny task
      for (let i = 0; i < escort?.length; i++) {
        let activeArray = this.getescortFormArray();
        let NewSleepingApi = escort[i] || {};

        let newTrainingArray = new FormGroup({
          escort_child_outside: new FormControl(
            NewSleepingApi.escort_child_outside || ''
          ),
          escort_child_outside_special_Instructions: new FormControl(
            NewSleepingApi.escort_child_outside_special_Instructions || ''
          ),
          escort_child_outside_done: new FormControl(NewSleepingApi.escort_child_outside_done || ''),
          escort_child_outside_time: new FormControl(
            NewSleepingApi.escort_child_outside_time
              ? new Date(NewSleepingApi.escort_child_outside_time)
              : null
          ),
          escort_child_outside_actual_time: new FormControl(
            NewSleepingApi.escort_child_outside_actual_time
              ? new Date(NewSleepingApi.escort_child_outside_actual_time)
              : null
          ),

          escort_child_outside_remark: new FormControl(NewSleepingApi.escort_child_outside_remark || ''),
          nurses: new FormControl(NewSleepingApi.nurses||null)

        });

        activeArray.insert(i, newTrainingArray);
      }
      //set value of other activity json in nanny task
      for (let i = 0; i < activity?.length; i++) {
        let escortArray = this.getActiveFormArray();
        let NewescortApi = activity[i] || {};

        let newescortArray = new FormGroup({
          other_activity: new FormControl(NewescortApi.other_activity || ''),
          activities_child_outside_special_Instructions: new FormControl(
            NewescortApi.activities_child_outside_special_Instructions || ''
          ),
          other_child_outside_time: new FormControl(
            NewescortApi.other_child_outside_time
              ? new Date(NewescortApi.other_child_outside_time)
              : null
          ),
          other_child_outside_actual_time: new FormControl(
            NewescortApi.other_child_outside_actual_time
              ? new Date(NewescortApi.other_child_outside_actual_time)
              : null
          ),

          other_child_outside_remark: new FormControl(NewescortApi.other_child_outside_remark || ''),
          other_child_outside_done: new FormControl(NewescortApi.other_child_outside_done || ''),
          nurses: new FormControl(NewescortApi.nurses||null)

        });

        escortArray.insert(i, newescortArray);
      }
      // set values of bottle seterization time in nanny task
      for (let i = 0; i < bottle?.length; i++) {
        let bottleArray = this.getbottle_sterilizationControls();
        let NewbottleApi = bottle[i] || {};


        let newbottleArray = new FormGroup({
          done_sterilization: new FormControl(
            NewbottleApi.done_sterilization
          ),
          sterilization_timeArray: new FormControl(
            NewbottleApi.sterilization_timeArray
              ? new Date(NewbottleApi.sterilization_timeArray)
              : null
          ),
          actual_sterilization_timeArray: new FormControl(
            NewbottleApi.actual_sterilization_timeArray ? new Date(NewbottleApi.actual_sterilization_timeArray) : null
          ),
          sterilization_remark: new FormControl(
            NewbottleApi.sterilization_remark || ''
          ),
          nurses: new FormControl(NewbottleApi.nurses||null)

        });

        bottleArray.insert(i, newbottleArray);
      }
      const sleeptime = res.data[0].nanny_task_details_json.sleeping_time;
      for (let i = 0; i < sleeptime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepArray();
        let time = sleeptime[i] || {};

        const newCalculationGroup = this.FB.group({
          sleeping_mother_time: new FormControl(
            time.sleeping_mother_time
              ? new Date(time.sleeping_mother_time)
              : null
          ),
          sleeping_mother_actual_time: new FormControl(
            time.sleeping_mother_actual_time
              ? new Date(time.sleeping_mother_actual_time)
              : null
          ),
          sleeping_mother_remark: new FormControl(time.sleeping_mother_remark || ''),
          sleeping_mother: new FormControl(time.sleeping_mother || ''),
          nurses: new FormControl(time.nurses||null)

        });
        CalculationArray.push(newCalculationGroup);
      }
      //nanny time
      const nannytime = res.data[0].nanny_task_details_json.nanny_time;
      for (let i = 0; i < nannytime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepnannyArray();
        let time = nannytime[i] || {};

        const newCalculationGroup = this.FB.group({
          sleeping_nanny_time: new FormControl(
            time.sleeping_nanny_time
              ? new Date(time.sleeping_nanny_time)
              : null
          ),
          sleeping_nanny_actual_time: new FormControl(
            time.sleeping_nanny_actual_time
              ? new Date(time.sleeping_nanny_actual_time)
              : null
          ),
          sleeping_nanny_remark: new FormControl(time.sleeping_nanny_remark || ''),

          sleeping_nanny: new FormControl(time.sleeping_nanny || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      for (let i = 0; i < formula?.length; i++) {

        let formularray = this.getFormulaCalculationControls();
        let Newformularray = formula[i] || {};

        let calformularray = new FormGroup({
          formula_timeArray: new FormControl(
            Newformularray.formula_timeArray
              ? new Date(Newformularray.formula_timeArray)
              : null
          ),
          nurse_feeding: new FormControl(
            Newformularray.nurse_feeding
              ? new Date(Newformularray.nurse_feeding)
              : null
          ),
          remark: new FormControl(Newformularray.remark || ''),
          amount: new FormControl(Newformularray.amount || ''),
          formula_remark: new FormControl(Newformularray.formula_remark || ''),
          feeding_checkbox: new FormControl(Newformularray.feeding_checkbox || ' '),
          is_same_formula: new FormControl(Newformularray.is_same_formula || 'Yes'),
          nurses: new FormControl(Newformularray.nurses||null)
        });

        formularray.insert(i, calformularray);
      }
      //skin care add button on edit
      for (let i = 0; i < skin?.length; i++) {
        let skinArray = this.getSkinFormArray();
        let skinFromApi = skin[i] || {};

        let newskinArray = new FormGroup({
          skin_care_lotion: new FormControl(skinFromApi.skin_care_lotion || ''),
          skin_care_baby_oil: new FormControl(
            skinFromApi.skin_care_baby_oil || ''
          ),
          skin_care_others: new FormControl(skinFromApi.skin_care_others || ''),
          skin_care_specify_produ: new FormControl(
            skinFromApi.skin_care_specify_produ || ''
          ),
          nurses: new FormControl(skinFromApi.nurses||null)
        });

        skinArray.insert(i, newskinArray);
      }
      //multiple feeding time
      const breastime = res.data[0].nanny_task_details_json.breast_feeding;
      for (let i = 0; i < breastime?.length; i++) {
        this.isBreast = true;
        let CalculationArray = this.getbreastarray();
        let time = breastime[i] || {};

        const newCalculationGroup = this.FB.group({
          feeding_breast_time: new FormControl(
            new Date(time.feeding_breast_time) || ''
          ),
          feeding_breast_checkbox: new FormControl(time.feeding_breast_checkbox || ''),
          breast_feeding_remark: new FormControl(time.breast_feeding_remark || ''),
          feeding_breast_actual_time: time.feeding_breast_actual_time ? new Date(time.feeding_breast_actual_time) : null,
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      //set value of feeding_breast in nanny task
      if (nannytask.feeding_breast == '1') {
        this.Breast = !this.Breast;
        this.oneToThreeYear.controls['feeding_frequency_breast'].enable();
        this.oneToThreeYear.controls['feeding_breast_time'].enable();
        this.oneToThreeYear.controls.feeding_frequency_breast.setValue(
          nannytask.feeding_frequency_breast
        );
        this.oneToThreeYear.controls.feeding_breast_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_breast_time)
        );
      } else {
        this.oneToThreeYear.controls['feeding_frequency_breast'].disable();
        this.oneToThreeYear.controls['feeding_breast_time'].disable();
      }
      //set value of feeding_type in nanny task
      if (nannytask.feeding_type == '1') {
        this.Formula = false;
        this.oneToThreeYear.controls['feeding_frequency'].enable();
        this.oneToThreeYear.controls['feeding_time'].enable();
        this.oneToThreeYear.controls['feeding_amount_per'].enable();
        this.oneToThreeYear.controls['type_of_formula'].enable();
        this.oneToThreeYear.controls['feeding_formula_time_end'].enable();
        this.oneToThreeYear.controls.feeding_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_time)
        );
        this.oneToThreeYear.controls.feeding_formula_time_end.setValue(
          this.utility_service.parseISOString(
            nannytask.feeding_formula_time_end
          )
        );
        this.oneToThreeYear.controls.feeding_amount_per.setValue(
          nannytask.feeding_amount_per
        );
        this.oneToThreeYear.controls.type_of_formula.setValue(
          nannytask.type_of_formula
        );
      } else {
        this.oneToThreeYear.controls['feeding_frequency'].disable();
        this.oneToThreeYear.controls['feeding_time'].disable();
        this.oneToThreeYear.controls['feeding_amount_per'].disable();
        this.oneToThreeYear.controls['type_of_formula'].disable();
        this.oneToThreeYear.controls['feeding_formula_time_end'].disable();
      }
      //set value of bathing_shower in nanny task
      if (nannytask.bathing_shower == true) {
        this.spongeBath = !this.spongeBath;
        this.oneToThreeYear.controls['bathing_shower_time'].enable();
        this.oneToThreeYear.controls['bathing_shower_frequency'].enable();
        this.oneToThreeYear.controls.bathing_shower_time.setValue(
          this.utility_service.parseISOString(nannytask.bathing_shower_time)
        );
        this.oneToThreeYear.controls.bathing_shower_frequency.setValue(
          nannytask.bathing_shower_frequency
        );
      } else {
        this.oneToThreeYear.controls['bathing_shower_time'].disable();
        this.oneToThreeYear.controls['bathing_shower_frequency'].disable();
      }
      //set value of bathing_tub in nanny task
      if (nannytask.bathing_tub == true) {
        this.tubBath = !this.tubBath;
        this.oneToThreeYear.controls['bathing_tub_time'].enable();
        this.oneToThreeYear.controls['bathing_tub_frequency'].enable();
        this.oneToThreeYear.controls.bathing_tub_time.setValue(
          this.utility_service.parseISOString(nannytask.bathing_tub_time)
        );
        this.oneToThreeYear.controls.bathing_tub_frequency.setValue(
          nannytask.bathing_tub_frequency
        );
      } else {
        this.oneToThreeYear.controls['bathing_tub_time'].disable();
        this.oneToThreeYear.controls['bathing_tub_frequency'].disable();
      }

      if (nannytask.food_medicine_allergy == '1') {
        this.oneToThreeYear.controls[
          'food_medicine_allergy_specify_prod'
        ].enable();
        this.oneToThreeYear.controls.food_medicine_allergy_specify_prod.setValue(
          nannytask.food_medicine_allergy_specify_prod
        );
      } else {
        this.oneToThreeYear.controls[
          'food_medicine_allergy_specify_prod'
        ].disable();
      }
      this.oneToThreeYear.controls.skin_care_lotion.setValue(
        nannytask.skin_care_lotion
      );
      this.oneToThreeYear.controls.skin_care_baby_oil.setValue(
        nannytask.skin_care_baby_oil
      );
      this.oneToThreeYear.controls.skin_care_others.setValue(
        nannytask.skin_care_others
      );
      this.oneToThreeYear.controls.skin_care_specify_produ.setValue(
        nannytask.skin_care_specify_produ
      );
      this.oneToThreeYear.controls.skin_care_Wipes.setValue(
        nannytask.skin_care_Wipes
      );
      this.oneToThreeYear.controls.all_time.setValue(nannytask.all_time);
      if (nannytask.onetime) {
        this.oneToThreeYear.controls.onetime.setValue(nannytask.onetime);
        this.One = false;
      }

      if (nannytask.all_time == true) {
        this.All = !this.All;
        this.oneToThreeYear.controls.bottle_sterilization_all.disable();
        this.oneToThreeYear.controls.bottle_sterilization_all.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_all
          )
        );

        this.oneToThreeYear.controls.actual_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.actual_sterilization_all_time
          )
        );
        this.oneToThreeYear.controls.onetime_Remark.setValue(nannytask.onetime_Remark);
        this.oneToThreeYear.controls.done_sterilization_onetime.setValue(nannytask.done_sterilization_onetime);
      } else {
        this.oneToThreeYear.controls.bottle_sterilization_all.disable();
      }

      if (nannytask.skin_care_Wipes == '1') {
        this.oneToThreeYear.controls['skin_care_special_instructions'].enable;
        this.oneToThreeYear.controls.skin_care_special_instructions.setValue(
          nannytask.skin_care_special_instructions
        );
      }
      this.oneToThreeYear.controls.changing_colthes_spiecal_instructions.setValue(
        nannytask.changing_colthes_spiecal_instructions
      );
      this.oneToThreeYear.controls.changing_colthes_frequency.setValue(
        nannytask.changing_colthes_frequency
      );
      this.oneToThreeYear.controls.remarks.setValue(nannytask.remarks);
      if (nannytask.sleeping_mother == '1') {
        this.With_Mother = !this.With_Mother;
        this.oneToThreeYear.controls.sleeping_mother_time.enable();
        this.oneToThreeYear.controls.sleeping_mother_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_time)
        );
      } else {
        this.oneToThreeYear.controls.sleeping_mother_time.disable();
      }
      if (nannytask.sleeping_nanny == '1') {
        this.With_Nanny = !this.With_Nanny;
        this.oneToThreeYear.controls.sleeping_nanny_time.enable();
        this.oneToThreeYear.controls.sleeping_nanny_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_time)
        );
      } else {
        this.oneToThreeYear.controls.sleeping_nanny_time.disable();
      }
      this.oneToThreeYear.controls.soild_other_snack_food_components.setValue(nannytask.soild_other_snack_food_components);
      this.oneToThreeYear.controls.solid_other_snack_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_time));
      this.oneToThreeYear.controls.solid_other_snack_actual_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_actual_time));

      //set value of solid_other_snack in nanny task
      // if (nannytask.solid_other_snack == '1') {
      //   this.Snack = !this.Snack;
      //   this.oneToThreeYear.controls.soild_other_snack_food_components.enable();

      //   this.oneToThreeYear.controls.solid_other_snack_actual_time.enable();
      //   this.oneToThreeYear.controls.soild_other_snack_food_components.setValue(
      //     nannytask.soild_other_snack_food_components
      //   );
      //   this.oneToThreeYear.controls.solid_other_snack_time.setValue(
      //     this.utility_service.parseISOString(nannytask.solid_other_snack_time)
      //   );
      //   this.oneToThreeYear.controls.solid_other_snack_actual_time.setValue(
      //     this.utility_service.parseISOString(nannytask.solid_other_snack_actual_time)
      //   );
      // }
      // else {
      //   this.oneToThreeYear.controls.soild_other_snack_food_components.disable();
      //   this.oneToThreeYear.controls.solid_other_snack_time.disable();
      //   this.oneToThreeYear.controls.solid_other_snack_actual_time.disable();
      // }
      //bathing section

      const tubtime = res.data[0].nanny_task_details_json.tub_bathing;
      for (let i = 0; i < tubtime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getbathingtubarray();
        let time = tubtime[i] || {};

        const newCalculationGroup = this.FB.group({
          bathing_tub_time: new FormControl(
            new Date(time.bathing_tub_time) || ''
          ),
          bathing_tub_actual_time: new FormControl(
            time.bathing_tub_actual_time ?
              new Date(time.bathing_tub_actual_time)
              : null
          ),
          tub_checkbox: new FormControl(time.tub_checkbox || ''),
          tubBathing_remark: new FormControl(time.tubBathing_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      const showertime = res.data[0].nanny_task_details_json.shower_bathing;
      for (let i = 0; i < showertime?.length; i++) {
        this.isBathingshower = true;
        let CalculationArray = this.getshowertubarray();
        let time = showertime[i] || {};

        const newCalculationGroup = this.FB.group({
          bathing_shower_time: new FormControl(
            new Date(time.bathing_shower_time) || ''
          ),
          bathing_shower_actual_time: new FormControl(
            time.bathing_shower_actual_time ? new Date(time.bathing_shower_actual_time) : null
          ),
          shower_checkbox: new FormControl(time.shower_checkbox || ''),
          shower_remark: new FormControl(time.shower_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }

      this.isSettingValueProgrammatically = false;
      this.disableForm();

    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    })
  }

  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('nurseTaskEditID');
    this.commonService.getEditNurseDailyTask(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailsInGrowthMonitoring = res.data[0];
      const nannytask = detailsInGrowthMonitoring.nanny_task_details_json;
      this.FirstEntry = nannytask
      
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
     
      //store value of all json in a variable
      const VitaminsMedication =
        res.data[0].nanny_task_details_json.vitamins_medications_json;
      const vaccine = res.data[0].nanny_task_details_json.vaccinations_json;
      const regular = res.data[0].nanny_task_details_json.solid_food;
      const lunch = res.data[0].nanny_task_details_json.lunch_food;
      const dinner = res.data[0].nanny_task_details_json.dinner_food;
      const training = res.data[0].nanny_task_details_json.potty_training;
      const sleeping = res.data[0].nanny_task_details_json.sleeping_training;
      const playing = res.data[0].nanny_task_details_json.outside_child;
      const escort = res.data[0].nanny_task_details_json.escort_child;
      const activity = res.data[0].nanny_task_details_json.activities_name;
      const bottle = res.data[0].nanny_task_details_json.bottle_sterilization;
      const formula = res.data[0].nanny_task_details_json.formula_calculation;
      const skin = res.data[0].nanny_task_details_json.skin_care;
      const nurseRemarks = res.data[0].nanny_task_details_json.nurse_remarks;
      this.oneToThreeYear.controls.baby_details_id.setValue(
        JSON.parse(detailsInGrowthMonitoring.baby_details_id)
      );
      this.oneToThreeYear.controls.feeding_frequency.setValue(
        detailsInGrowthMonitoring.nanny_task_details_json.feeding_frequency
      );
     
      //set values of all formcontrols in nanny task
      this.oneToThreeYear.controls.feeding_prepared_mother.setValue(
        nannytask.feeding_prepared_mother
      );
      this.oneToThreeYear.controls.feeding_prepared_other.setValue(
        nannytask.feeding_prepared_other
      );
      this.oneToThreeYear.controls.feeding_prepared_nanny.setValue(
        nannytask.feeding_prepared_nanny
      );
      this.oneToThreeYear.controls.feeding_type.setValue(
        nannytask.feeding_type
      );
      this.oneToThreeYear.controls.feeding_breast.setValue(
        nannytask.feeding_breast
      );
      this.oneToThreeYear.controls.solid_other_snack.setValue(
        nannytask.solid_other_snack
      );
      this.oneToThreeYear.controls.regular_meal_dinner.setValue(
        nannytask.regular_meal_dinner
      );
      this.oneToThreeYear.controls.regular_meal_lunch.setValue(
        nannytask.regular_meal_lunch
      );
      this.oneToThreeYear.controls.bathing_tub.setValue(nannytask.bathing_tub);
      this.oneToThreeYear.controls.bathing_shower.setValue(
        nannytask.bathing_shower
      );
      this.oneToThreeYear.controls.sleeping_mother.setValue(
        nannytask.sleeping_mother
      );
      this.oneToThreeYear.controls.sleeping_nanny.setValue(
        nannytask.sleeping_nanny
      );

      this.oneToThreeYear.controls.temperature_monitoring_frequency.setValue(
        nannytask.temperature_monitoring_frequency
      );
      this.oneToThreeYear.controls.sleeping_independently.setValue(
        nannytask.sleeping_independently
      );
      this.oneToThreeYear.controls.food_medicine_allergy.setValue(
        nannytask.food_medicine_allergy
      );
      this.oneToThreeYear.controls.diaper_change_frequency.setValue(
        nannytask.diaper_change_frequency
      );

      // changing_clothes
      const changingclothes =
        res.data[0].nanny_task_details_json.changing_clothes;
      for (let i = 0; i < changingclothes?.length; i++) {
        let clothesarray = this.getClothesFormArray();
        let clothes = changingclothes[i] || {};

        const newclothesGroup = this.FB.group({
          changing_colthes: new FormControl(clothes.changing_colthes || ''),
          Changing_time: new FormControl(
            clothes.Changing_time
              ? new Date(clothes.Changing_time)
              : null
          ),
          Changing_actual_time: new FormControl(
            clothes.Changing_actual_time
              ? new Date(clothes.Changing_actual_time)
              : null
          ),
          clothe_remark: new FormControl(clothes.clothe_remark || ''),
          nurses: new FormControl(clothes.nurses||null)
        });
        clothesarray.push(newclothesGroup);
      }
      this.oneToThreeYear.controls.diaper_change_frequency.setValue(nannytask.diaper_change_frequency);
      this.oneToThreeYear.controls.diaper_label.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label)
      );
      this.oneToThreeYear.controls.diaper_label_end_time.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label_end_time)
      );
      //diaper frequency set value

      const adddiaper = res.data[0].nanny_task_details_json.diaper_calculation;
      for (let i = 0; i < adddiaper?.length; i++) {
        this.isdiaper = true;
        let bottleArray = this.getdiaperCalculationControls();
        let bottleFromApi = adddiaper[i] || {};

        let newbottleArray = new FormGroup({
          diaper_timeArray: new FormControl(bottleFromApi.diaper_timeArray ? new Date(bottleFromApi.diaper_timeArray)
              : null),
          diaper_actual_time: new FormControl(
            bottleFromApi.diaper_actual_time
              ? new Date(bottleFromApi.diaper_actual_time)
              : null
          ),
          remark: new FormControl(bottleFromApi.remark || ''),
          amount: new FormControl(bottleFromApi.amount || ''),
          diaperRemark: new FormControl(bottleFromApi.diaperRemark || ''),
          diaper_checkbox: new FormControl(bottleFromApi.diaper_checkbox || ''),
          nurses: new FormControl(bottleFromApi.nurses||null)
        });

        bottleArray.insert(i, newbottleArray);
      }

      // set nurse Remarks
      // for (let i = 0; i < nurseRemarks?.length; i++) {
      //   let remarkArray = this.getNurseRemarks();
      //   let remarks = skin[i] || {};

      //   let newremarkArray = new FormGroup({
      //     nurse_remark: new FormControl(remarks.nurse_remark || ''),

      //   });

      //   remarkArray.insert(i, newremarkArray);
      // }
      // set value of diaper changes
      const diapherchange =
        res.data[0].nanny_task_details_json.diaper_change_times;
      for (let i = 0; i < diapherchange?.length; i++) {
        let CalculationArray = this.getDiaperChangeControls();
        let diaper = diapherchange[i] || {};

        const newCalculationGroup = this.FB.group({
          change: new FormControl(diaper.change || ''),
          time: new FormControl(
            diaper.time
              ? new Date(diaper.time)
              : null
          ),
          diaper_remark: new FormControl(diaper.diaper_remark || ''),
          nurses: new FormControl(diaper.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      // Assuming nannytask has a property named 'formula_calculation' which is an array
      // and you want to set the 'remark' value for the first item in the array
      if (
        nannytask.formula_calculation &&
        nannytask.formula_calculation?.length > 0
      ) {
        const firstFormulaCalculation = nannytask.formula_calculation[0]; // Adjust the index as needed
        const formulaControls = this.oneToThreeYear.get(
          'formula_calculation'
        ) as FormArray;

        if (formulaControls && formulaControls?.length > 0) {
          formulaControls
            .at(0)
            .get('remark')
            .setValue(firstFormulaCalculation.remark);
        }
      }

      //set value of vitamin json in nanny task
      for (let i = 0; i < VitaminsMedication?.length; i++) {
        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};

        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin || ''),
          medicine_time: new FormArray([]),
          frequency: new FormControl(VitaminsMedicationFromApi.frequency || ''),
          nurses: new FormControl(VitaminsMedicationFromApi.nurses||null)
        });

        vitaminArray.insert(i, newVitaminArray);

        this.ismedicine = true;

        for (let j = 0; j < VitaminsMedicationFromApi.medicine_time?.length; j++) {

          const viamincalc = (this.oneToThreeYear.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray;
          const bathingcalculationgroup = this.FB.group({
            time: new FormControl(new Date(VitaminsMedicationFromApi.medicine_time[j].time)),
            vitamin_checkbox: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_checkbox),
            vitamin_remark: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_remark),
            actual_time: new FormControl(
              VitaminsMedicationFromApi.medicine_time[j].actual_time
                ? new Date(VitaminsMedicationFromApi.medicine_time[j].actual_time)
                : null
            ),
            nurses: new FormControl(VitaminsMedicationFromApi.medicine_time[j].nurses)
          });
          viamincalc.push(bathingcalculationgroup);

        }
      }
      //set value of vaccine json in nanny task
      for (let i = 0; i < vaccine?.length; i++) {
        let vaccinationArray = this.getVaccinationFormArray();
        let vaccineApi = vaccine[i] || {};

        let newVaccinationArray = new FormGroup({
          vaccination: new FormControl(vaccineApi.vaccination || ''),
          date: new FormControl(
            vaccineApi.date ? new Date(vaccineApi.date) : null
          ),
          
        });

        vaccinationArray.insert(i, newVaccinationArray);
      }
      //set value of regular food json in nanny task
      for (let i = 0; i < regular?.length; i++) {
        let RegularArray = this.getRegularFormArray();
        let regularApi = regular[i] || {};

        let newRegularArray = new FormGroup({
          regular_meal_type: new FormControl(
            regularApi.regular_meal_type || ''
          ),
          regular_meal_time: new FormControl(
            regularApi.regular_meal_time
              ? new Date(regularApi.regular_meal_time)
              : null
          ),
          regular_meal_actual_time: new FormControl(
            regularApi.regular_meal_actual_time
              ? new Date(regularApi.regular_meal_actual_time)
              : null
          ),

          regular_meal_food_components: new FormControl(
            regularApi.regular_meal_food_components || ''
          ),
          nurses: new FormControl(regularApi.nurses||null)
        });

        RegularArray.insert(i, newRegularArray);
      }
      //set value of lunch json in nanny task
      for (let i = 0; i < lunch?.length; i++) {
        let LunchArray = this.getLunchFormArray();
        let lunchApi = lunch[i] || {};

        let newLunchArray = new FormGroup({
          regular_meal_lunch: new FormControl(
            lunchApi.regular_meal_lunch || ''
          ),
          regular_meal_lunch_time: new FormControl(
            lunchApi.regular_meal_lunch_time
              ? new Date(lunchApi.regular_meal_lunch_time)
              : null
          ),
          regular_meal_lunch_actual_time: new FormControl(
            lunchApi.regular_meal_lunch_actual_time
              ? new Date(lunchApi.regular_meal_lunch_actual_time)
              : null
          ),
          regular_meal_lunch_food_components: new FormControl(
            lunchApi.regular_meal_lunch_food_components || ''
          ),
          nurses: new FormControl(lunchApi.nurses||null)
        });

        LunchArray.insert(i, newLunchArray);
      }
      //set value of dinner json in nanny task
      for (let i = 0; i < dinner?.length; i++) {
        let dinnerArray = this.getDinnerFormArray();
        let dinnerApi = dinner[i] || {};

        let newdinnerArray = new FormGroup({
          regular_meal_dinner: new FormControl(
            dinnerApi.regular_meal_dinner || ''
          ),
          regular_meal_dinner_time: new FormControl(
            dinnerApi.regular_meal_dinner_time
              ? new Date(dinnerApi.regular_meal_dinner_time)
              : null
          ),
          regular_meal_dinner_actual_time: new FormControl(
            dinnerApi.regular_meal_dinner_actual_time
              ? new Date(dinnerApi.regular_meal_dinner_actual_time)
              : null
          ),
          regular_meal_dinner_food_components: new FormControl(
            dinnerApi.regular_meal_dinner_food_components || ''
          ),
          nurses: new FormControl(dinnerApi.nurses||null)
        });

        dinnerArray.insert(i, newdinnerArray);
      }
      for (let i = 0; i < nurseRemarks?.length; i++) {
        let remarkArray = this.getNurseRemarks();
        let remarks = nurseRemarks[i] || {};

        let newremarkArray = new FormGroup({
          nurse_remark: new FormControl(remarks.nurse_remark || ''),
          nurse_remark_time: new FormControl(remarks.nurse_remark_time ? remarks.nurse_remark_time : null),
          nurses: new FormControl(remarks.nurses||null)
        });

        remarkArray.insert(i, newremarkArray);
      }
      if (nurseRemarks?.length < 1 || nurseRemarks == undefined) {
        this.addNurseRemark('add', 0);
      }
      //set value of training json in nanny task
      for (let i = 0; i < training?.length; i++) {
        let TrainingArray = this.getTrainingFormArray();
        let trainingApi = training[i] || {};

        let newTrainingArray = new FormGroup({
          potty_training: new FormControl(trainingApi.potty_training || ''),
          training_child: new FormControl(trainingApi.training_child || ''),
          training_child_remark: new FormControl(trainingApi.training_child_remark || ''),
          done_training_child: new FormControl(trainingApi.done_training_child || ''),
          time_training_child: new FormControl(
            trainingApi.time_training_child
              ? new Date(trainingApi.time_training_child)
              : null
          ),
          time_training_actual_child: new FormControl(
            trainingApi.time_training_actual_child
              ? new Date(trainingApi.time_training_actual_child)
              : null
          ),
          nurses: new FormControl(trainingApi.nurses||null)
        });

        TrainingArray.insert(i, newTrainingArray);
      }
      //set value of sleep training json in nanny task
      for (let i = 0; i < sleeping?.length; i++) {
        let SleepingArray = this.getsleepTrainingFormArray();
        let NewSleepingApi = sleeping[i] || {};

        let newTrainingArray = new FormGroup({
          sleeping_time: new FormControl(
            NewSleepingApi.sleeping_time
              ? new Date(NewSleepingApi.sleeping_time)
              : null
          ),
          nurses: new FormControl(NewSleepingApi.nurses||null)
        });

        SleepingArray.insert(i, newTrainingArray);
      }
      //set value of play outside json in nanny task
      for (let i = 0; i < playing?.length; i++) {
        let playingArray = this.getPlayFormArray();
        let playApi = playing[i] || {};

        let newpalyingArray = new FormGroup({
          play_child_outside: new FormControl(playApi.play_child_outside || ''),
          play_child_outside_special_Instructions: new FormControl(
            playApi.play_child_outside_special_Instructions || ''
          ),
          play_child_outside_done: new FormControl(playApi.play_child_outside_done || ''),
          play_child_outside_time: new FormControl(
            playApi.play_child_outside_time
              ? new Date(playApi.play_child_outside_time)
              : null
          ),
          play_child_outside_actual_time: new FormControl(
            playApi.play_child_outside_actual_time
              ? new Date(playApi.play_child_outside_actual_time)
              : null
          ),
          play_child_outside_remark: new FormControl(playApi.play_child_outside_remark || ''),
          nurses: new FormControl(playApi.nurses||null)
        });

        playingArray.insert(i, newpalyingArray);
      }
      //set value of escort json in nanny task
      for (let i = 0; i < escort?.length; i++) {
        let activeArray = this.getescortFormArray();
        let NewSleepingApi = escort[i] || {};

        let newTrainingArray = new FormGroup({
          escort_child_outside: new FormControl(
            NewSleepingApi.escort_child_outside || ''
          ),
          escort_child_outside_special_Instructions: new FormControl(
            NewSleepingApi.escort_child_outside_special_Instructions || ''
          ),
          escort_child_outside_done: new FormControl(NewSleepingApi.escort_child_outside_done || ''),
          escort_child_outside_time: new FormControl(
            NewSleepingApi.escort_child_outside_time
              ? new Date(NewSleepingApi.escort_child_outside_time)
              : null
          ),
          escort_child_outside_actual_time: new FormControl(
            NewSleepingApi.escort_child_outside_actual_time
              ? new Date(NewSleepingApi.escort_child_outside_actual_time)
              : null
          ),
          escort_child_outside_remark: new FormControl(NewSleepingApi.escort_child_outside_remark || ''),
          nurses: new FormControl(NewSleepingApi.nurses||null)
        });

        activeArray.insert(i, newTrainingArray);
      }
      //set value of other activity json in nanny task
      for (let i = 0; i < activity?.length; i++) {
        let escortArray = this.getActiveFormArray();
        let NewescortApi = activity[i] || {};

        let newescortArray = new FormGroup({
          other_activity: new FormControl(NewescortApi.other_activity || ''),
          activities_child_outside_special_Instructions: new FormControl(
            NewescortApi.activities_child_outside_special_Instructions || ''
          ),
          other_child_outside_time: new FormControl(
            NewescortApi.other_child_outside_time
              ? new Date(NewescortApi.other_child_outside_time)
              : null
          ),
          other_child_outside_actual_time: new FormControl(
            NewescortApi.other_child_outside_actual_time
              ? new Date(NewescortApi.other_child_outside_actual_time)
              : null
          ),
          other_child_outside_remark: new FormControl(NewescortApi.other_child_outside_remark || ''),
          other_child_outside_done: new FormControl(NewescortApi.other_child_outside_done || ''),
          nurses: new FormControl(NewescortApi.nurses||null)
        });

        escortArray.insert(i, newescortArray);
      }
      // set values of bottle seterization time in nanny task
      for (let i = 0; i < bottle?.length; i++) {
        let bottleArray = this.getbottle_sterilizationControls();
        let NewbottleApi = bottle[i] || {};


        let newbottleArray = new FormGroup({
          done_sterilization: new FormControl(
            NewbottleApi.done_sterilization
          ),
          sterilization_timeArray: new FormControl(
            NewbottleApi.sterilization_timeArray
              ? new Date(NewbottleApi.sterilization_timeArray)
              : null
          ),
          actual_sterilization_timeArray: new FormControl(
            NewbottleApi.actual_sterilization_timeArray ? new Date(NewbottleApi.actual_sterilization_timeArray) : null
          ),
          sterilization_remark: new FormControl(
            NewbottleApi.sterilization_remark || ''
          ),
          nurses: new FormControl(NewbottleApi.nurses||null)
        });

        bottleArray.insert(i, newbottleArray);
      }
      const sleeptime = res.data[0].nanny_task_details_json.sleeping_time;
      for (let i = 0; i < sleeptime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepArray();
        let time = sleeptime[i] || {};

        const newCalculationGroup = this.FB.group({
          sleeping_mother_time: new FormControl(
            time.sleeping_mother_time
              ? new Date(time.sleeping_mother_time)
              : null
          ),
          sleeping_mother_actual_time: new FormControl(
            time.sleeping_mother_actual_time
              ? new Date(time.sleeping_mother_actual_time)
              : null
          ),
          sleeping_mother_remark: new FormControl(time.sleeping_mother_remark || ''),
          sleeping_mother: new FormControl(time.sleeping_mother || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      //nanny time
      const nannytime = res.data[0].nanny_task_details_json.nanny_time;
      for (let i = 0; i < nannytime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepnannyArray();
        let time = nannytime[i] || {};

        const newCalculationGroup = this.FB.group({
         sleeping_nanny_time: new FormControl(
            time.sleeping_nanny_time
              ? new Date(time.sleeping_nanny_time)
              : null
          ),
          sleeping_nanny_actual_time: new FormControl(
            time.sleeping_nanny_actual_time
              ? new Date(time.sleeping_nanny_actual_time)
              : null
          ),
          sleeping_nanny_remark: new FormControl(time.sleeping_nanny_remark || ''),
          sleeping_nanny: new FormControl(time.sleeping_nanny || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      for (let i = 0; i < formula?.length; i++) {

        let formularray = this.getFormulaCalculationControls();
        let Newformularray = formula[i] || {};

        let calformularray = new FormGroup({
          formula_timeArray: new FormControl(
            Newformularray.formula_timeArray
              ? new Date(Newformularray.formula_timeArray)
              : null
          ),
          nurse_feeding: new FormControl(
            Newformularray.nurse_feeding
              ? new Date(Newformularray.nurse_feeding)
              : null
          ),
          remark: new FormControl(Newformularray.remark || ''),
          amount: new FormControl(Newformularray.amount || ''),
          formula_remark: new FormControl(Newformularray.formula_remark || ''),
          is_same_formula: new FormControl(Newformularray.is_same_formula || 'Yes'),
          feeding_checkbox: new FormControl(Newformularray.feeding_checkbox || ''),
          nurses: new FormControl(Newformularray.nurses||null)
        });

        formularray.insert(i, calformularray);
      }
      //skin care add button on edit
      for (let i = 0; i < skin?.length; i++) {
        let skinArray = this.getSkinFormArray();
        let skinFromApi = skin[i] || {};

        let newskinArray = new FormGroup({
          skin_care_lotion: new FormControl(skinFromApi.skin_care_lotion || ''),
          skin_care_baby_oil: new FormControl(
            skinFromApi.skin_care_baby_oil || ''
          ),
          skin_care_others: new FormControl(skinFromApi.skin_care_others || ''),
          skin_care_specify_produ: new FormControl(
            skinFromApi.skin_care_specify_produ || ''
          ),
          nurses: new FormControl(skinFromApi.nurses||null)
        });

        skinArray.insert(i, newskinArray);
      }
      //multiple feeding time
      const breastime = res.data[0].nanny_task_details_json.breast_feeding;
      for (let i = 0; i < breastime?.length; i++) {
        this.isBreast = true;
        let CalculationArray = this.getbreastarray();
        let time = breastime[i] || {};

        const newCalculationGroup = this.FB.group({
          feeding_breast_time: new FormControl(
            new Date(time.feeding_breast_time) || ''
          ),
          feeding_breast_checkbox: new FormControl(time.feeding_breast_checkbox || ''),
          breast_feeding_remark: new FormControl(time.breast_feeding_remark || ''),
          feeding_breast_actual_time: time.feeding_breast_actual_time ? new Date(time.feeding_breast_actual_time) : null,
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      //set value of feeding_breast in nanny task
      if (nannytask.feeding_breast == '1') {
        this.Breast = !this.Breast;
        this.oneToThreeYear.controls['feeding_frequency_breast'].enable();
        this.oneToThreeYear.controls['feeding_breast_time'].enable();
        this.oneToThreeYear.controls.feeding_frequency_breast.setValue(
          nannytask.feeding_frequency_breast
        );
        this.oneToThreeYear.controls.feeding_breast_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_breast_time)
        );
      } else {
        this.oneToThreeYear.controls['feeding_frequency_breast'].disable();
        this.oneToThreeYear.controls['feeding_breast_time'].disable();
      }
      //set value of feeding_type in nanny task
      if (nannytask.feeding_type == '1') {
        this.Formula = false;
        this.oneToThreeYear.controls['feeding_frequency'].enable();
        this.oneToThreeYear.controls['feeding_time'].enable();
        this.oneToThreeYear.controls['feeding_amount_per'].enable();
        this.oneToThreeYear.controls['type_of_formula'].enable();
        this.oneToThreeYear.controls['feeding_formula_time_end'].enable();
        this.oneToThreeYear.controls.feeding_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_time)
        );
        this.oneToThreeYear.controls.feeding_formula_time_end.setValue(
          this.utility_service.parseISOString(
            nannytask.feeding_formula_time_end
          )
        );
        this.oneToThreeYear.controls.feeding_amount_per.setValue(
          nannytask.feeding_amount_per
        );
        this.oneToThreeYear.controls.type_of_formula.setValue(
          nannytask.type_of_formula
        );
      } else {
        this.oneToThreeYear.controls['feeding_frequency'].disable();
        this.oneToThreeYear.controls['feeding_time'].disable();
        this.oneToThreeYear.controls['feeding_amount_per'].disable();
        this.oneToThreeYear.controls['type_of_formula'].disable();
        this.oneToThreeYear.controls['feeding_formula_time_end'].disable();
      }
      //set value of bathing_shower in nanny task
      if (nannytask.bathing_shower == true) {
        this.spongeBath = !this.spongeBath;
        this.oneToThreeYear.controls['bathing_shower_time'].enable();
        this.oneToThreeYear.controls['bathing_shower_frequency'].enable();
        this.oneToThreeYear.controls.bathing_shower_time.setValue(
          this.utility_service.parseISOString(nannytask.bathing_shower_time)
        );
        this.oneToThreeYear.controls.bathing_shower_frequency.setValue(
          nannytask.bathing_shower_frequency
        );
      } else {
        this.oneToThreeYear.controls['bathing_shower_time'].disable();
        this.oneToThreeYear.controls['bathing_shower_frequency'].disable();
      }
      //set value of bathing_tub in nanny task
      if (nannytask.bathing_tub == true) {
        this.tubBath = !this.tubBath;
        this.oneToThreeYear.controls['bathing_tub_time'].enable();
        this.oneToThreeYear.controls['bathing_tub_frequency'].enable();
        this.oneToThreeYear.controls.bathing_tub_time.setValue(
          this.utility_service.parseISOString(nannytask.bathing_tub_time)
        );
        this.oneToThreeYear.controls.bathing_tub_frequency.setValue(
          nannytask.bathing_tub_frequency
        );
      } else {
        this.oneToThreeYear.controls['bathing_tub_time'].disable();
        this.oneToThreeYear.controls['bathing_tub_frequency'].disable();
      }

      if (nannytask.food_medicine_allergy == '1') {
        this.oneToThreeYear.controls[
          'food_medicine_allergy_specify_prod'
        ].enable();
        this.oneToThreeYear.controls.food_medicine_allergy_specify_prod.setValue(
          nannytask.food_medicine_allergy_specify_prod
        );
      } else {
        this.oneToThreeYear.controls[
          'food_medicine_allergy_specify_prod'
        ].disable();
      }
      this.oneToThreeYear.controls.skin_care_lotion.setValue(
        nannytask.skin_care_lotion
      );
      this.oneToThreeYear.controls.skin_care_baby_oil.setValue(
        nannytask.skin_care_baby_oil
      );
      this.oneToThreeYear.controls.skin_care_others.setValue(
        nannytask.skin_care_others
      );
      this.oneToThreeYear.controls.skin_care_specify_produ.setValue(
        nannytask.skin_care_specify_produ
      );
      this.oneToThreeYear.controls.skin_care_Wipes.setValue(
        nannytask.skin_care_Wipes
      );
      this.oneToThreeYear.controls.all_time.setValue(nannytask.all_time);
      if (nannytask.onetime) {
        this.oneToThreeYear.controls.onetime.setValue(nannytask.onetime);
        this.One = false;
      }

       if (nannytask.all_time == true) {
        this.All = !this.All;
        this.oneToThreeYear.controls.bottle_sterilization_all.disable();
        this.oneToThreeYear.controls.bottle_sterilization_all.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_all
          )
        );

        this.oneToThreeYear.controls.actual_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.actual_sterilization_all_time
          )
        );
        this.oneToThreeYear.controls.onetime_Remark.setValue(nannytask.onetime_Remark);
        this.oneToThreeYear.controls.done_sterilization_onetime.setValue(nannytask.done_sterilization_onetime);
      } else {
        this.oneToThreeYear.controls.bottle_sterilization_all.disable();
      }

      if (nannytask.skin_care_Wipes == '1') {
        this.oneToThreeYear.controls['skin_care_special_instructions'].enable;
        this.oneToThreeYear.controls.skin_care_special_instructions.setValue(
          nannytask.skin_care_special_instructions
        );
      }
      this.oneToThreeYear.controls.changing_colthes_spiecal_instructions.setValue(
        nannytask.changing_colthes_spiecal_instructions
      );
      this.oneToThreeYear.controls.changing_colthes_frequency.setValue(
        nannytask.changing_colthes_frequency
      );
      this.oneToThreeYear.controls.remarks.setValue(nannytask.remarks);
      if (nannytask.sleeping_mother == '1') {
        this.With_Mother = !this.With_Mother;
        this.oneToThreeYear.controls.sleeping_mother_time.enable();
        this.oneToThreeYear.controls.sleeping_mother_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_time)
        );
      } else {
        this.oneToThreeYear.controls.sleeping_mother_time.disable();
      }
      if (nannytask.sleeping_nanny == '1') {
        this.With_Nanny = !this.With_Nanny;
        this.oneToThreeYear.controls.sleeping_nanny_time.enable();
        this.oneToThreeYear.controls.sleeping_nanny_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_time)
        );
      } else {
        this.oneToThreeYear.controls.sleeping_nanny_time.disable();
      }
      //set value of solid_other_snack in nanny task
      this.oneToThreeYear.controls.soild_other_snack_food_components.setValue(nannytask.soild_other_snack_food_components);
      this.oneToThreeYear.controls.solid_other_snack_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_time));
      this.oneToThreeYear.controls.solid_other_snack_actual_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_actual_time));
      // if (nannytask.solid_other_snack == '1') {
      //   this.Snack = !this.Snack;
      //   this.oneToThreeYear.controls.soild_other_snack_food_components.enable();
      //   this.oneToThreeYear.controls.solid_other_snack_actual_time.enable();
      //   this.oneToThreeYear.controls.soild_other_snack_food_components.setValue(
      //     nannytask.soild_other_snack_food_components
      //   );
      //   this.oneToThreeYear.controls.solid_other_snack_time.setValue(
      //     this.utility_service.parseISOString(nannytask.solid_other_snack_time)
      //   );
      //   this.oneToThreeYear.controls.solid_other_snack_actual_time.setValue(
      //     this.utility_service.parseISOString(nannytask.solid_other_snack_actual_time)
      //   );
      // }
      // else {
      //   this.oneToThreeYear.controls.soild_other_snack_food_components.disable();
      //   this.oneToThreeYear.controls.solid_other_snack_time.disable();
      //   this.oneToThreeYear.controls.solid_other_snack_actual_time.disable();
      // }
      //bathing section

      const tubtime = res.data[0].nanny_task_details_json.tub_bathing;
      for (let i = 0; i < tubtime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getbathingtubarray();
        let time = tubtime[i] || {};

        const newCalculationGroup = this.FB.group({
          bathing_tub_time: new FormControl(
            new Date(time.bathing_tub_time) || ''
          ),
          bathing_tub_actual_time: new FormControl(
            time.bathing_tub_actual_time ?
              new Date(time.bathing_tub_actual_time)
              : null
          ),
          tub_checkbox: new FormControl(time.tub_checkbox || ''),
          tubBathing_remark: new FormControl(time.tubBathing_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      const showertime = res.data[0].nanny_task_details_json.shower_bathing;
      for (let i = 0; i < showertime?.length; i++) {
        this.isBathingshower = true;
        let CalculationArray = this.getshowertubarray();
        let time = showertime[i] || {};

        const newCalculationGroup = this.FB.group({
          bathing_shower_time: new FormControl(
            new Date(time.bathing_shower_time) || ''
          ),
          bathing_shower_actual_time: new FormControl(
            time.bathing_shower_actual_time ? new Date(time.bathing_shower_actual_time) : null
          ),
          shower_checkbox: new FormControl(time.shower_checkbox || ''),
          shower_remark: new FormControl(time.shower_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      this.isSettingValueProgrammatically = false;
      this.disableForm();
      
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call

    })
  }

  getDiaperChangeControls(): FormArray {
    return this.oneToThreeYear.get('diaper_change_times') as FormArray;
  }


  // getTimeLabel(index: number): string {
  //   let changetime = this.getDiaperChangeControls();
  //   let time = new Date(changetime.at(index).get('time').value);
  //   return time.toLocaleTimeString('en-US', {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //   });
  // }
  // DiaperChange(event: any) {
  //   if (!this.isSettingValueProgrammatically) {
  //     let start = new Date();
  //     let end = new Date();
  //     start.setHours(0, 0, 0, 0);
  //     end.setHours(23, 59, 59, 999);
  //     this.clearDiaperControls();
  //     let updated = start;

  //     while (updated < end) {
  //       if (updated < end) {
  //         // let time = updated.getHours()+':'+updated.getMinutes();

  //         let time = new Date();

  //         time.setHours(updated.getHours());
  //         time.setMinutes(updated.getMinutes());

  //         this.addDiaperChange(time);
  //       }
  //       updated.setHours(updated.getHours() + event);
  //     }
  //   }
  // }
  adddiaperObject(string: any, index: any) {
    let diaperArray = this.getDiaperChangeControls();
    if (string == 'add') {
      let newdiaperArray = new FormGroup({
        change: new FormControl(''),
        time: new FormControl(''),
        diaper_remark: new FormControl(''),
        nurses: new FormControl(null)
      });
      diaperArray.insert(index + 1, newdiaperArray);
    } else {
      diaperArray.removeAt(index);
    }
  }
  getClothesFormArray() {
    return this.oneToThreeYear.get('changing_clothes') as FormArray;
  }
  addClothesObject(string: any, index: any) {
    let ClothesArray = this.getClothesFormArray();
    if (string == 'add') {
      let newclothesArray = new FormGroup({
        changing_colthes: new FormControl(''),
        Changing_time: new FormControl(''),
        clothe_remark: new FormControl(''),
        Changing_actual_time: new FormControl(''),
        nurses: new FormControl(null)
      });
      ClothesArray.insert(index + 1, newclothesArray);
    } else {
      ClothesArray.removeAt(index);
    }
  }
  disableAllRegularFoodControls(checkboxValue: boolean, index: number): void {
    const regularFoodArray = this.getRegularFormArray();
    const control = regularFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls when checkbox is checked

      control.get('regular_meal_food_components').enable();
      // Add validators if needed
      // control.get('regular_meal_time').setValidators([Validators.required]);
      // control.get('regular_meal_food_components').setValidators([Validators.required]);
    } else {
      // Disable controls when checkbox is unchecked
      control.get('regular_meal_time').disable();
      control.get('regular_meal_food_components').disable();
      // Clear validators and set values to null
      control.get('regular_meal_time').clearValidators();
      control.get('regular_meal_food_components').clearValidators();
      // control.get('regular_meal_time').setValue(null);
      // control.get('regular_meal_food_components').setValue(null);
    }

    // Update validity
    control.get('regular_meal_time').updateValueAndValidity();
    control.get('regular_meal_food_components').updateValueAndValidity();
  }
  disableAlllunchFoodControls(checkboxValue: boolean, index: number): void {
    const lunchFoodArray = this.getLunchFormArray();
    const control = lunchFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls when checkbox is checked

      control.get('regular_meal_lunch_food_components').enable();
    } else {
      // Enable controls when checkbox is unchecked
      control.get('regular_meal_lunch_time').disable();
      control.get('regular_meal_lunch_food_components').disable();
      // Clear validators and set values to null
      control.get('regular_meal_lunch_time').clearValidators();
      control.get('regular_meal_lunch_food_components').clearValidators();
      // control.get('regular_meal_lunch_time').setValue(null);
      // control.get('regular_meal_lunch_food_components').setValue(null);
    }

    // Update validity
    control.get('regular_meal_lunch_time').updateValueAndValidity();
    control.get('regular_meal_lunch_food_components').updateValueAndValidity();
  }

  disableAlldinnerFoodControls(checkboxValue: boolean, index: number): void {
    const regularFoodArray = this.getDinnerFormArray();

    // for (let i = 0; i < regularFoodArray?.length; i++) {
    const control = regularFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls and set values to null when checkbox is checked

      control.get('regular_meal_dinner_food_components').enable();
    } else {
      // Enable controls when checkbox is unchecked
      control.get('regular_meal_dinner_time').disable();
      control.get('regular_meal_dinner_food_components').disable();
      control.get('regular_meal_dinner_time').clearValidators();
      control.get('regular_meal_dinner_food_components').clearValidators();
      // control.get('regular_meal_dinner_time').setValue(null);
      // control.get('regular_meal_dinner_food_components').setValue(null);
    }
    control.get('regular_meal_dinner_time').updateValueAndValidity();
    control.get('regular_meal_dinner_food_components').updateValueAndValidity();
  }
  getId() {
    this.planId = this.utility_service.getData(this.breadcrumb[0].title);
  }
  //edit functionality on form


  clearmedicinecalculation(i) {
    ((this.oneToThreeYear.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray).clear();
  }



  addmedicationcalculation(index: number): void {

    const viamincalc = (this.oneToThreeYear.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
    const bathingcalculationgroup = this.FB.group({
      time: new FormControl(),
      vitamin_checkbox: new FormControl(null),
      vitamin_remark: new FormControl(null),
      actual_time: new FormControl(null),
      nurses: new FormControl(null)
    });
    viamincalc.push(bathingcalculationgroup);
  }

  isset(variable) {
    if (variable != 'undefined' && variable != null && variable != '') {
      return true;
    } else {
      return false;
    }
  }

  getFormulaCalculationControls(): FormArray {
    return this.oneToThreeYear.get('formula_calculation') as FormArray;
  }

  // get bottle_sterilization array
  getbottle_sterilizationControls(): FormArray {
    return this.oneToThreeYear.get('bottle_sterilization') as FormArray;
  }

  // clear feeding array
  clearFormulaCalculation() {
    (this.oneToThreeYear.get('formula_calculation') as FormArray).clear();
  }

  // clear Bottle Sterilization array
  clearbottle_sterilizationControls() {
    (this.oneToThreeYear.get('bottle_sterilization') as FormArray).clear();
  }

  // add new object in feeding array
  addFeedingCalculation(time: any): void {
    const feedingCalculationArray = this.getFormulaCalculationControls();

    const newFeedingCalculationGroup = this.FB.group({
      formula_timeArray: new FormControl(time),
      nurse_feeding: new FormControl(),
      remark: new FormControl(null),
      amount: new FormControl(null),
      formula_remark: new FormControl(null),
      is_same_formula: new FormControl(null),
      feeding_checkbox: new FormControl(null),
      nurses: new FormControl(null)
    });
    feedingCalculationArray.push(newFeedingCalculationGroup);
  }

  // add new object in Bottle Sterilization array
  addbottle_sterilization(time: any): void {
    const bottle_sterilizationArray = this.getbottle_sterilizationControls();

    const newbottle_sterilizationGroup = this.FB.group({
      done_sterilization: new FormControl(null),
      sterilization_timeArray: new FormControl(time),
      actual_sterilization_timeArray: new FormControl(null),
      sterilization_remark: new FormControl(null),
      nurses: new FormControl(null)
    });
    bottle_sterilizationArray.push(newbottle_sterilizationGroup);
  }

  // auto generate feeding time and remark fields
  createfields() {
    let start = new Date(this.oneToThreeYear.controls.feeding_time.value);
    let end = new Date(
      this.oneToThreeYear.controls.feeding_formula_time_end.value
    );
    let frequency = this.oneToThreeYear.controls.feeding_frequency.value;
    let setbottle_sterilization = new Date(
      this.oneToThreeYear.controls.feeding_time.value
    );

    if (!this.isSettingValueProgrammatically) {

      if (
        this.isset(this.oneToThreeYear.controls.feeding_time.value) &&
        this.isset(
          this.oneToThreeYear.controls.feeding_formula_time_end.value
        ) &&
        this.isset(frequency)
      ) {
       

        let array = this.getFormulaCalculationControls();
        let updated = start;
        this.clearFormulaCalculation();
        this.clearbottle_sterilizationControls();

        while (updated < end) {
          if (updated < end) {
            // let time = updated.getHours()+':'+updated.getMinutes();

            let time = new Date();

            time.setHours(updated.getHours());
            time.setMinutes(updated.getMinutes());

            let sterilization_time = new Date();

            sterilization_time.setHours(updated.getHours() - 2);
            sterilization_time.setMinutes(updated.getMinutes());

            this.addFeedingCalculation(time);
            this.addbottle_sterilization(sterilization_time);
          }
          updated.setHours(updated.getHours() + frequency);
        }
      }
    }
  }

  getmedicinearray(index) {
    return (this.oneToThreeYear.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
  }

  getFrequency(index: number) {
    let vitaminFormArray = this.getVitaminFormArray();
    if (vitaminFormArray && vitaminFormArray.controls?.length > index) {
      let vitaminscontrols = vitaminFormArray.controls[index] as FormGroup;
      let frequency = vitaminscontrols.get('frequency').value;

      return frequency;
    }
    return null; // or any default value if needed
  }
  addmedicinefield(index: number) {
   

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.getFrequency(index); // Replace 0 with the desired index

      if (this.isset(frequency)) {
       

        // let array = this.getmedicinearray();
        this.ismedicine = true;
        this.clearmedicinecalculation(index);

        for (let i = 0; i < frequency; i++) {
          this.addmedicationcalculation(index);
        }
      }
    }
  }

  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.getVitaminFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        vitamin: new FormControl(''),
        medicine_time: new FormArray([]),
        frequency: new FormControl(''),
        nurses: new FormControl(null)

      });
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    // const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  addVaccinationObject(string: any, index: any) {
    let vaccinationArray = this.getVaccinationFormArray();
    if (string == 'add') {
      let newVaccinationArray = new FormGroup({
        vaccination: new FormControl(''),
        date: new FormControl(''),
      });
      vaccinationArray.insert(index + 1, newVaccinationArray);
    } else {
      vaccinationArray.removeAt(index);
    }
  }
  addRegularObject(string: any, index: any) {
    let RegularArray = this.getRegularFormArray();
    if (string == 'add') {
      let newRegularArray = new FormGroup({
        regular_meal_type: new FormControl(''),
        regular_meal_time: new FormControl(''),
        regular_meal_food_components: new FormControl(''),
        regular_meal_actual_time: new FormControl(''),
        nurses: new FormControl(null)
      });
      RegularArray.insert(index + 1, newRegularArray);
    } else {
      RegularArray.removeAt(index);
    }
  }
  addLunchObject(string: any, index: any) {
    let LunchArray = this.getLunchFormArray();
    if (string == 'add') {
      let newLunchArray = new FormGroup({
        regular_meal_lunch: new FormControl(''),
        regular_meal_lunch_time: new FormControl(''),
        regular_meal_lunch_food_components: new FormControl(''),
        regular_meal_lunch_actual_time: new FormControl(''),
        nurses: new FormControl(null)
      });
      LunchArray.insert(index + 1, newLunchArray);
    } else {
      LunchArray.removeAt(index);
    }
  }
  addDinnerObject(string: any, index: any) {
    let DinnerArray = this.getDinnerFormArray();
    if (string == 'add') {
      let newDinnerArray = new FormGroup({
        regular_meal_dinner: new FormControl(''),
        regular_meal_dinner_time: new FormControl(''),
        regular_meal_dinner_food_components: new FormControl(''),
        regular_meal_dinner_actual_time: new FormControl(''),
        nurses: new FormControl(null)
      });
      DinnerArray.insert(index + 1, newDinnerArray);
    } else {
      DinnerArray.removeAt(index);
    }
  }
  addPlayObject(string: any, index: any) {
    let playArray = this.getPlayFormArray();
    if (string == 'add') {
      let newplayArray = new FormGroup({
        play_child_outside: new FormControl(''),
        play_child_outside_special_Instructions: new FormControl(''),
        play_child_outside_time: new FormControl(),
        play_child_outside_actual_time: new FormControl(),
        play_child_outside_remark: new FormControl(),
        play_child_outside_done: new FormControl(),
        nurses: new FormControl(null)
      });
      playArray.insert(index + 1, newplayArray);
    } else {
      playArray.removeAt(index);
    }
  }
  addEscortObject(string: any, index: any) {
    let escortArray = this.getescortFormArray();
    if (string == 'add') {
      let newescortArray = new FormGroup({
        escort_child_outside: new FormControl(''),
        escort_child_outside_special_Instructions: new FormControl(''),
        escort_child_outside_time: new FormControl(),
        escort_child_outside_actual_time: new FormControl(),
        escort_child_outside_remark: new FormControl(),
        escort_child_outside_done: new FormControl(),
        nurses: new FormControl(null)
      });
      escortArray.insert(index + 1, newescortArray);
    } else {
      escortArray.removeAt(index);
    }
  }
  addActiveObject(string: any, index: any) {
    let activityArray = this.getActiveFormArray();
    if (string == 'add') {
      let newactivityArray = new FormGroup({
        other_activity: new FormControl(''),
        activities_child_outside_special_Instructions: new FormControl(''),
        other_child_outside_time: new FormControl(),
        other_child_outside_remark: new FormControl(),
        other_child_outside_done: new FormControl(),
        other_child_outside_actual_time: new FormControl(),
        nurses: new FormControl(null)
      });
      activityArray.insert(index + 1, newactivityArray);
    } else {
      activityArray.removeAt(index);
    }
  }
  addTrainingObject(string: any, index: any) {
    let TrainingArray = this.getTrainingFormArray();
    if (string == 'add') {
      let newTrainingArray = new FormGroup({
        potty_training: new FormControl(null),
        training_child: new FormControl(null),
        done_training_child: new FormControl(null),
        time_training_child: new FormControl(null),
        time_training_actual_child: new FormControl(null),
        training_child_remark: new FormControl(null),
        nurses: new FormControl(null)
      });
      TrainingArray.insert(index + 1, newTrainingArray);
    } else {
      TrainingArray.removeAt(index);
    }
  }
  addSleepTrainingObject(string: any, index: any) {
    let sleepTrainingArray = this.getsleepTrainingFormArray();
    if (string == 'add') {
      let newsleepTrainingArray = new FormGroup({
        sleeping_time: new FormControl(''),
        nurses: new FormControl(null)
      });
      sleepTrainingArray.insert(index + 1, newsleepTrainingArray);
    } else {
      sleepTrainingArray.removeAt(index);
    }
  }
  //add skin
  addSkinObject(string: any, index: any) {
    let skinArray = this.getSkinFormArray();
    if (string == 'add') {
      let newskinArray = new FormGroup({
        skin_care_lotion: new FormControl(''),
        skin_care_baby_oil: new FormControl(''),
        skin_care_others: new FormControl(''),
        skin_care_specify_produ: new FormControl(''),
        nurses: new FormControl(null)
      });
      skinArray.insert(index + 1, newskinArray);
    } else {
      skinArray.removeAt(index);
    }
  }
  // get baby details function
  getBabyDetails() {
    this.spinner.show()
    this.commonService.getBabyDetails().subscribe(
      (res: any) => {
        this.spinner.hide()

        this.babyValues = res?.data;

      },
      (error: HttpErrorResponse) => {
        this.spinner.hide()
      }
    );
  }
  getRemainingDetails(event: any) {
    let data = this.babyValues?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter?.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    // if (this.years >= 1 && this.years <= 3) {
    //   this.flag = true;
    // } else {
    //   this.flag = false;
    //   this.messageService.add({
    //     severity: 'info',
    //     summary: 'Info',
    //     detail: 'Your child does not meet the eligibility criteria for this plan.',
    //   });
    // }(this.years >= 1 && this.years < 3) || (this.years === 3 && this.days <= 0)

    if (
      (this.years >= 1 && this.years < 3) ||
      (this.years === 3 && this.days <= 0)
    ) {
      this.flag = true;
    } else {
      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail:
          'Your child does not meet the eligibility criteria for this plan.',
      });
    }
  }

  handleInput(event: any, value: string) {
    if (value == 'Bathing') {
      if (event.target.value == 'Tub') {
        // this.oneToThreeYear.controls.bathing_tub.setValue(event?.target?.checked ? 1 : 0)
        this.oneToThreeYear.controls.bathing_tub.setValue(
          event?.target?.checked ? 1 : 0
        );

        this.tubBath = !this.tubBath;
        if (event.target.checked) {
          this.oneToThreeYear.controls.bathing_tub_frequency.enable();
          this.oneToThreeYear.controls.bathing_tub_time.enable();
        } else {
          this.oneToThreeYear.controls.bathing_tub_frequency.disable();
          this.oneToThreeYear.controls.bathing_tub_time.disable();
          this.oneToThreeYear.controls['bathing_tub_frequency'].setValue('');
          this.oneToThreeYear.controls['bathing_tub_time'].setValue('');
        }
        if (event.target.checked) {
          this.oneToThreeYear.controls['bathing_tub_frequency'].setValidators([
            Validators.required,
          ]);
          // this.oneToThreeYear.controls['bathing_tub_time'].setValidators([
          //   Validators.required,
          // ]);
        } else {
          this.oneToThreeYear.controls[
            'bathing_tub_frequency'
          ].clearValidators();
          this.oneToThreeYear.controls['bathing_tub_time'].clearValidators();
        }
        this.oneToThreeYear.controls[
          'bathing_tub_frequency'
        ].updateValueAndValidity();
        this.oneToThreeYear.controls[
          'bathing_tub_time'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'Shower') {
        this.spongeBath = !this.spongeBath;
        if (event.target.checked) {
          this.oneToThreeYear.controls.bathing_shower_frequency.enable();
          this.oneToThreeYear.controls.bathing_shower_time.enable();
        } else {
          this.oneToThreeYear.controls.bathing_shower_frequency.disable();
          this.oneToThreeYear.controls.bathing_shower_time.disable();
          this.oneToThreeYear.controls['bathing_shower_frequency'].setValue('');
          this.oneToThreeYear.controls['bathing_shower_time'].setValue('');
        }
        if (event.target.checked) {
          this.oneToThreeYear.controls[
            'bathing_shower_frequency'
          ].setValidators([Validators.required]);
          // this.oneToThreeYear.controls['bathing_shower_time'].setValidators([
          //   Validators.required,
          // ]);
        } else {
          this.oneToThreeYear.controls[
            'bathing_shower_frequency'
          ].clearValidators();
          this.oneToThreeYear.controls['bathing_shower_time'].clearValidators();
        }
        this.oneToThreeYear.controls[
          'bathing_shower_frequency'
        ].updateValueAndValidity();
        this.oneToThreeYear.controls[
          'bathing_shower_time'
        ].updateValueAndValidity();
      }
    } else if (value == 'milk') {
      if (event.target.value == 'Breast') {
        this.oneToThreeYear.controls.feeding_breast.setValue(
          event?.target?.checked ? 1 : 0
        );
        this.Breast = !this.Breast;
        if (this.Breast == true) {
          this.oneToThreeYear.controls['feeding_frequency_breast'].setValue('');
          this.oneToThreeYear.controls['feeding_breast_time'].setValue('');
        } else {
          this.oneToThreeYear.controls['feeding_frequency_breast'].enable();
          this.oneToThreeYear.controls['feeding_breast_time'].enable();
        }
        if (event.target.checked) {
          this.oneToThreeYear.controls[
            'feeding_frequency_breast'
          ].setValidators([Validators.required]);


        } else {
          this.oneToThreeYear.controls[
            'feeding_frequency_breast'
          ].clearValidators();
          this.oneToThreeYear.controls['feeding_breast_time'].clearValidators();
        }
        this.oneToThreeYear.controls[
          'feeding_frequency_breast'
        ].updateValueAndValidity();
        this.oneToThreeYear.controls[
          'feeding_breast_time'
        ].updateValueAndValidity();
      } else if (event.target.value == 'Formula') {
        this.oneToThreeYear.controls.feeding_type.setValue(
          event?.target?.checked ? 1 : 0
        );
        this.Formula = !this.Formula;
        if (this.Formula == true) {
          this.oneToThreeYear.controls['feeding_frequency'].setValue('');
          this.oneToThreeYear.controls['feeding_time'].setValue('');
          this.oneToThreeYear.controls['feeding_amount_per'].setValue('');
          this.oneToThreeYear.controls['type_of_formula'].setValue('');
        } else {
          this.oneToThreeYear.controls['feeding_time'].enable();
          this.oneToThreeYear.controls['feeding_amount_per'].enable();
          this.oneToThreeYear.controls['type_of_formula'].enable();
        }
        if (event.target.checked) {
          this.oneToThreeYear.controls['feeding_frequency'].setValidators([
            Validators.required,
          ]);

          this.oneToThreeYear.controls['feeding_time'].setValidators([
            Validators.required,
          ]);
          this.oneToThreeYear.controls['feeding_amount_per'].setValidators([
            Validators.required,
          ]);
          this.oneToThreeYear.controls['type_of_formula'].setValidators([
            Validators.required,
          ]);
        } else {
          this.oneToThreeYear.controls['feeding_frequency'].clearValidators();
          this.oneToThreeYear.controls['feeding_time'].clearValidators();
          this.oneToThreeYear.controls['feeding_amount_per'].clearValidators();
          this.oneToThreeYear.controls['type_of_formula'].clearValidators();
        }
        this.oneToThreeYear.controls[
          'feeding_frequency'
        ].updateValueAndValidity();
        this.oneToThreeYear.controls['feeding_time'].updateValueAndValidity();
        this.oneToThreeYear.controls[
          'feeding_amount_per'
        ].updateValueAndValidity();
        this.oneToThreeYear.controls[
          'type_of_formula'
        ].updateValueAndValidity();
        //   if (this.Formula == true) {
        //     this.oneToThreeYear.controls['formula_frequency'].setValue('');
        //     this.oneToThreeYear.controls['feeding_time'].setValue('');
        //     this.oneToThreeYear.controls['type_of_formula'].setValue('');
        //     this.oneToThreeYear.controls['feeding_amount_per'].setValue('');
        //   }
        // }
        // if(event.target.checked){
        //   this.oneToThreeYear.controls['feeding_frequency'].setValidators([Validators.required]);

        //   this.oneToThreeYear.controls['feeding_time'].setValidators([Validators.required]);
        //   this.oneToThreeYear.controls['feeding_amount_per'].setValidators([Validators.required]);
        //   this.oneToThreeYear.controls['type_of_formula'].setValidators([Validators.required]);
        // }else {
        //   this.oneToThreeYear.controls['feeding_frequency'].clearValidators();
        //   this.oneToThreeYear.controls['feeding_time'].clearValidators();
        //   this.oneToThreeYear.controls['feeding_amount_per'].clearValidators();
        //   this.oneToThreeYear.controls['type_of_formula'].clearValidators();
        // }
        // this.oneToThreeYear.controls['feeding_frequency'].updateValueAndValidity();
        // this.oneToThreeYear.controls['feeding_time'].updateValueAndValidity();
        // this.oneToThreeYear.controls['feeding_amount_per'].updateValueAndValidity();
        // this.oneToThreeYear.controls['type_of_formula'].updateValueAndValidity();
      }
    } else if (value == 'feeding') {
      if (event.target.value == 'All') {
        this.oneToThreeYear.controls.all_time.setValue(
          event?.target?.checked ? 1 : 0
        );
        this.All = !this.All;
        if (this.All == true) {
          this.oneToThreeYear.controls['bottle_sterilization_all'].setValue('');
        }
        if (event.target.checked) {
          this.oneToThreeYear.controls[
            'bottle_sterilization_all'
          ].setValidators([Validators.required]);
        } else {
          this.oneToThreeYear.controls[
            'bottle_sterilization_all'
          ].clearValidators();
        }
        this.oneToThreeYear.controls[
          'bottle_sterilization_all'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'One') {
        this.oneToThreeYear.controls.onetime.setValue(
          event?.target?.checked ? 1 : 0
        );
        this.One = !this.One;
        if (this.One == true) {
          this.oneToThreeYear.controls['bottle_sterilization_onetime'].setValue(
            ''
          );
        } else {
          this.oneToThreeYear.controls[
            'bottle_sterilization_onetime'
          ].clearValidators();
        }
        this.oneToThreeYear.controls[
          'bottle_sterilization_onetime'
        ].updateValueAndValidity();
      }
    } else if (value == 'regularMeal') {
      if (event.target.value == 'Snack') {
        this.oneToThreeYear.controls.solid_other_snack.setValue(
          event?.target?.checked ? 1 : 0
        );
        this.Snack = !this.Snack;
        if (this.Snack) {
          this.oneToThreeYear.controls[
            'soild_other_snack_food_components'
          ].setValue('');

          this.oneToThreeYear.controls['solid_other_snack_actual_time'].setValue('');
        }
        if (event.target.checked) {

          this.oneToThreeYear.controls['solid_other_snack_actual_time'].setValidators([
            Validators.required,
          ]);
          this.oneToThreeYear.controls[
            'soild_other_snack_food_components'
          ].setValidators([Validators.required]);
        } else {

          this.oneToThreeYear.controls[
            'solid_other_snack_actual_time'
          ].clearValidators();
          this.oneToThreeYear.controls[
            'soild_other_snack_food_components'
          ].clearValidators();

        }

        this.oneToThreeYear.controls[
          'soild_other_snack_food_components'
        ].updateValueAndValidity();
      }
      // if (event.target.value == 'Breakfast') {
      //   this.Breakfast = !this.Breakfast;
      //   this.oneToThreeYear.controls.regular_meal_type.setValue(event?.target?.checked ? 1 :0);
      //   if (this.Breakfast) {
      //     this.oneToThreeYear.controls['regular_meal_time'].setValue('');
      //     this.oneToThreeYear.controls[
      //       'regular_meal_food_components'
      //     ].setValue('');
      //   }
      //   if(event.target.checked){
      //     this.oneToThreeYear.controls['regular_meal_time'].setValidators([Validators.required]);
      //     this.oneToThreeYear.controls['regular_meal_food_components'].setValidators([Validators.required]);
      //   }
      //   else {
      //     this.oneToThreeYear.controls['regular_meal_time'].clearValidators();
      //     this.oneToThreeYear.controls['regular_meal_food_components'].clearValidators();
      //   }
      //   this.oneToThreeYear.controls['regular_meal_time'].updateValueAndValidity();
      //   this.oneToThreeYear.controls['regular_meal_food_components'].updateValueAndValidity();
      // }
      if (event.target.value == 'Lunch') {
        this.Lunch = !this.Lunch;
        this.oneToThreeYear.controls.regular_meal_lunch.setValue(
          event?.target?.checked ? 1 : 0
        );
        if (this.Lunch) {
          this.oneToThreeYear.controls['regular_meal_lunch_time'].setValue('');
          this.oneToThreeYear.controls[
            'regular_meal_lunch_food_components'
          ].setValue('');
        }
        if (event.target.checked) {
          this.oneToThreeYear.controls['regular_meal_lunch_time'].setValidators(
            [Validators.required]
          );
          this.oneToThreeYear.controls[
            'regular_meal_lunch_food_components'
          ].setValidators([Validators.required]);
        } else {
          this.oneToThreeYear.controls[
            'regular_meal_lunch_time'
          ].clearValidators();
          this.oneToThreeYear.controls[
            'regular_meal_lunch_food_components'
          ].clearValidators();
        }
        this.oneToThreeYear.controls[
          'regular_meal_lunch_time'
        ].updateValueAndValidity();
        this.oneToThreeYear.controls[
          'regular_meal_lunch_food_components'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'Dinner') {
        this.Dinner = !this.Dinner;
        this.oneToThreeYear.controls.regular_meal_dinner.setValue(
          event?.target?.checked ? 1 : 0
        );
        if (this.Dinner) {
          this.oneToThreeYear.controls['regular_meal_dinner_time'].setValue('');
          this.oneToThreeYear.controls[
            'regular_meal_dinner_food_components'
          ].setValue('');
        }
        if (event.target.checked) {
          this.oneToThreeYear.controls[
            'regular_meal_dinner_time'
          ].setValidators([Validators.required]);
          this.oneToThreeYear.controls[
            'regular_meal_dinner_food_components'
          ].setValidators([Validators.required]);
        } else {
          this.oneToThreeYear.controls[
            'regular_meal_dinner_time'
          ].clearValidators();
          this.oneToThreeYear.controls[
            'regular_meal_dinner_food_components'
          ].clearValidators();
        }
        this.oneToThreeYear.controls[
          'regular_meal_dinner_time'
        ].updateValueAndValidity();
        this.oneToThreeYear.controls[
          'regular_meal_dinner_food_components'
        ].updateValueAndValidity();
      }
    } else if (value == 'fnmAllergy') {
      if (event.target.value == '1') {
        this.oneToThreeYear.controls.food_medicine_allergy_specify_prod.enable();
      } else {
        this.oneToThreeYear.controls.food_medicine_allergy_specify_prod.disable();
        this.oneToThreeYear.controls.food_medicine_allergy_specify_prod.setValue(
          null
        );
      }
    } else if (value == 'escort') {
      if (event.target.value == '1') {
        this.escort = false;
      } else if (event.target.value == '0') {
        this.escort = true;
        this.oneToThreeYear.controls[
          'escort_child_outside_special_Instructions'
        ].setValue('');
      }
    } else if (value == 'wipes') {
      if (event.target.value == '1' && event.target.checked) {
        this.oneToThreeYear.controls.skin_care_special_instructions.enable();
      } else if (event.target.value == '0' && event.target.checked) {
        this.oneToThreeYear.controls.skin_care_special_instructions.disable();
        this.oneToThreeYear.controls['skin_care_special_instructions'].setValue(
          null
        );
      }
    } else if (value == 'playing') {
      if (event.target.value == '1') {
        this.playWith = false;
      } else if (event.target.value == '0') {
        this.playWith = true;
        this.oneToThreeYear.controls[
          'play_child_outside_special_Instructions'
        ].setValue('');
      }
    }
    // else if (value == 'sleeping') {
    //   if (event.target.value == 'With_Mother') {
    //     this.oneToThreeYear.controls.sleeping_mother.setValue(
    //       event?.target?.checked ? 1 : 0
    //     );
    //     this.With_Mother = !this.With_Mother;
    //     if (this.With_Mother == true) {
    //       this.oneToThreeYear.controls['sleeping_mother_time'].setValue('');
    //     }
    //     if (event.target.checked) {
    //       this.oneToThreeYear.controls['sleeping_mother_time'].setValidators([
    //         Validators.required,
    //       ]);
    //     } else {
    //       this.oneToThreeYear.controls[
    //         'sleeping_mother_time'
    //       ].clearValidators();
    //     }
    //     this.oneToThreeYear.controls[
    //       'sleeping_mother_time'
    //     ].updateValueAndValidity();
    //   }
    //   if (event.target.value == 'With_Nanny') {
    //     this.oneToThreeYear.controls.sleeping_nanny.setValue(
    //       event?.target?.checked ? 1 : 0
    //     );
    //     this.With_Nanny = !this.With_Nanny;
    //     if (this.With_Nanny == true) {
    //       this.oneToThreeYear.controls['sleeping_nanny_time'].setValue('');
    //     }
    //     if (event.target.checked) {
    //       this.oneToThreeYear.controls['sleeping_nanny_time'].setValidators([
    //         Validators.required,
    //       ]);
    //     } else {
    //       this.oneToThreeYear.controls['sleeping_nanny_time'].clearValidators();
    //     }
    //     this.oneToThreeYear.controls[
    //       'sleeping_nanny_time'
    //     ].updateValueAndValidity();
    //   }
    // }
    else if (value == 'others') {
      if (event.target.value == 'Lotion') {
        this.oneToThreeYear.controls.skin_care_lotion.setValue(
          event?.target?.checked ? 1 : 0
        );
      } else if (event.target.value == 'Baby Oil') {
        this.oneToThreeYear.controls.skin_care_baby_oil.setValue(
          event?.target?.checked ? 1 : 0
        );
      } else if (event.target.value == 'others') {
        this.oneToThreeYear.controls.skin_care_others.setValue(
          event?.target?.checked ? 1 : 0
        );
      }
    }
  }

  addValidator(index: number, event: any, value: string) {

    this.control = this.getDiaperChangeControls().at(index).get('time');
    if (value === 'addDiaper' && event.target.checked) {
      this.control.setValidators([Validators.required]);
    }
    else {
      this.control.clearValidators();
    }
    this.control.updateValueAndValidity();
  }

  submitForm(oneToThreeYear: any) {
    this.oneToThreeYear.enable();
    let structuredObject = {
      baby_details_id: this.oneToThreeYear.get('baby_details_id').value,
      remarks:this.oneToThreeYear.get('remarks').value,
      nurse_remarks:this.oneToThreeYear.get('nurse_remarks').value,
      bathing:[
        {
          bathing_tub:this.oneToThreeYear.get('bathing_tub').value,
          bathing_tub_frequency:this.oneToThreeYear.get('bathing_tub_frequency').value,
          tub_bathing:this.oneToThreeYear.get('tub_bathing').value,
          bathing_shower:this.oneToThreeYear.get('bathing_shower').value,
          bathing_shower_frequency:this.oneToThreeYear.get('bathing_shower_frequency').value,
          shower_bathing:this.oneToThreeYear.get('shower_bathing').value,
        }
      ],
      bottle_sterilization:[
        {
          bottle_sterilization:this.oneToThreeYear.get('bottle_sterilization').value,
          bottle_sterilization_all:this.oneToThreeYear.get('bottle_sterilization_all').value,
          all_time:this.oneToThreeYear.get('all_time').value,
          onetime:this.oneToThreeYear.get('onetime').value,
        }
      ],
       clothes:[
        {
        changing_clothes:this.oneToThreeYear.get('changing_clothes').value,
        changing_colthes_spiecal_instructions:this.oneToThreeYear.get('changing_colthes_spiecal_instructions').value,
       }
      ],
      diaper:[{
        diaper_calculation:this.oneToThreeYear.get('diaper_calculation').value,
        diaper_change_frequency:this.oneToThreeYear.get('diaper_change_frequency').value,
        diaper_label:this.oneToThreeYear.get('diaper_label').value,
        diaper_label_end_time:this.oneToThreeYear.get('diaper_label_end_time').value,
        diaper_change_times:this.oneToThreeYear.get('diaper_change_times').value
      }],
      
      breastfeeding:[
        {
         feeding_frequency_breast:this.oneToThreeYear.get('feeding_frequency_breast').value,
         breast_feeding:this.oneToThreeYear.get('breast_feeding').value,
        }
      ],
      formula_feeding:[
        {
         feeding_frequency:this.oneToThreeYear.get('feeding_frequency').value,
         feeding_formula_time_end:this.oneToThreeYear.get('feeding_formula_time_end').value,
          feeding_time:this.oneToThreeYear.get('feeding_time').value,
          feeding_amount_per:this.oneToThreeYear.get('feeding_amount_per').value,
          type_of_formula:this.oneToThreeYear.get('type_of_formula').value,
             feeding_type: this.oneToThreeYear.get('feeding_type').value,
             formula_calculation:this.oneToThreeYear.get('formula_calculation').value,
        }
      ],
      prepared:[
        {
          feeding_prepared_mother:this.oneToThreeYear.get('feeding_prepared_mother').value,
          feeding_prepared_nanny:this.oneToThreeYear.get('feeding_prepared_nanny').value,
           feeding_prepared_other: this.oneToThreeYear.get('feeding_prepared_other').value,
        }
      ],
      sleeping:[{
        sleeping_time:this.oneToThreeYear.get('sleeping_time').value,
        nanny_time:this.oneToThreeYear.get('nanny_time').value,
      }],
      skin:[{
        skin_care:this.oneToThreeYear.get('skin_care').value,
      }],
      vitamin:[
        {
          vitamins_medications_json:this.oneToThreeYear.get('vitamins_medications_json').value,
        }
      ],
      wipes:[
        {
          skin_care_special_instructions:this.oneToThreeYear.get('skin_care_special_instructions').value,
          skin_care_Wipes:this.oneToThreeYear.get('skin_care_Wipes').value,
        }
      ],
      food:[{
        solid_food:this.oneToThreeYear.get('solid_food').value,
        lunch_food:this.oneToThreeYear.get('lunch_food').value,
        dinner_food:this.oneToThreeYear.get('dinner_food').value,
        solid_other_snack_time:this.oneToThreeYear.get('solid_other_snack_time').value,
        soild_other_snack_food_components:this.oneToThreeYear.get('soild_other_snack_food_components').value,
      }],
      allergy:[
        {
          food_medicine_allergy:this.oneToThreeYear.get('food_medicine_allergy').value,
          food_medicine_allergy_specify_prod:this.oneToThreeYear.get('food_medicine_allergy_specify_prod').value,
        }
      ],
      sleep_training:[
        {
          sleeping_training:this.oneToThreeYear.get('sleeping_training').value,
        }
      ],
      potty_training:[
        {
          potty_training:this.oneToThreeYear.get('potty_training').value,
        }
      ],
      activities:[
        {
          outside_child:this.oneToThreeYear.get('outside_child').value,
          escort_child:this.oneToThreeYear.get('escort_child').value,
          activities_name:this.oneToThreeYear.get('activities_name').value,
        }
      ]

  };
    this.spinner.show()
    window.scroll(0, 0);
    if (oneToThreeYear.valid) {
      let data = JSON.parse(JSON.stringify(this.oneToThreeYear.value));
      data.baby_plan_id = 3;
      data.vaccinations_json = JSON.stringify(data?.vaccinations_json);
      data.vitamins_medications_json = JSON.stringify(
        data?.vitamins_medications_json
      );
      data.baby_plan_id = 3;

      data.feeding_prepared_mother =
        data?.feeding_prepared_mother === true ? 1 : 0;
      data.feeding_prepared_nanny =
        data?.feeding_prepared_nanny === true ? 1 : 0;
      data.feeding_prepared_other =
        data?.feeding_prepared_other === true ? 1 : 0;
      let timeArray = [
        'solid_other_snack_actual_time',
        'bottle_sterilization_all',
        'bottle_sterilization_onetime',
        'solid_other_snack_time',
        'regular_meal_time',
        'regular_meal_lunch_time',
        'regular_meal_dinner_time',
        'sleeping_mother_time',
        'sleeping_nanny_time',
        'bathing_tub_time',
        'bathing_shower_time',
        'feeding_breast_time',
        'feeding_time',
      ];

      let payload = {
        nanny_task_details_json: JSON.stringify(this.oneToThreeYear.value),
        baby_plan_id: 3,
        baby_details_id: this.oneToThreeYear.value.baby_details_id,
        structured_json: JSON.stringify(structuredObject),
      };
      const jsonString: string = JSON.stringify(this.oneToThreeYear.value);
      // Correctly call the method from the service
      const processedData = this.utility_service.processFormData(JSON.parse(jsonString));
      console.log("Processed Data: ", processedData);
    
    


    payload.nanny_task_details_json = JSON.stringify(processedData)
      // this.spinner.show();
      let id = localStorage.getItem('nurseTaskEditID');
      if (this.isedit == true) {
        this.commonService.putEditNurseDailytask(payload, id)
          .then((res: any) => {
            this.spinner.hide()
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            // oneToThreeYear.reset();
            setTimeout(() => {
              this.router.navigateByUrl('portal/first-screen')
                ;
            }, 400);
            // this.spinner.hide();
          })
          .catch((error: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
            });
          });
      }
      else {
        // this.spinner.show();
        this.commonService
          .postBabyDailyTaskDetail(payload)
          .then((res: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            // oneToThreeYear.reset();
            setTimeout(() => {
              this.router.navigateByUrl('portal/first-screen')

            }, 400);
            // this.spinner.hide();
          })
          .catch((error: any) => {
            this.spinner.hide()
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
            });
          });
      }
    } else {
      this.spinner.hide()
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData?.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }

  checkPermission(permission: number) {
   
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }
  getVitaminFormArray() {
    return this.oneToThreeYear.get('vitamins_medications_json') as FormArray;
  }
  getVaccinationFormArray() {
    return this.oneToThreeYear.get('vaccinations_json') as FormArray;
  }
  getRegularFormArray() {
    return this.oneToThreeYear.get('solid_food') as FormArray;
  }
  getDinnerFormArray() {
    return this.oneToThreeYear.get('dinner_food') as FormArray;
  }
  getLunchFormArray() {
    return this.oneToThreeYear.get('lunch_food') as FormArray;
  }
  getPlayFormArray() {
    return this.oneToThreeYear.get('outside_child') as FormArray;
  }
  getescortFormArray() {
    return this.oneToThreeYear.get('escort_child') as FormArray;
  }
  getActiveFormArray() {
    return this.oneToThreeYear.get('activities_name') as FormArray;
  }
  getTrainingFormArray() {
    return this.oneToThreeYear.get('potty_training') as FormArray;
  }
  getsleepTrainingFormArray() {
    return this.oneToThreeYear.get('sleeping_training') as FormArray;
  }
  getSkinFormArray() {
    return this.oneToThreeYear.get('skin_care') as FormArray;
  }
  getbathingtubarray() {
    return this.oneToThreeYear.get('tub_bathing') as FormArray;
  }
  clearbathingtubcalculation() {
    (this.oneToThreeYear.get('tub_bathing') as FormArray).clear();
  }
  addbathingtubcalculation(): void {
    const bathingcalculationtub = this.getbathingtubarray();
    const bathingcalculationgroup1 = this.FB.group({
      tub_checkbox: new FormControl(),
      bathing_tub_time: new FormControl(),
      tubBathing_remark: new FormControl(),
      bathing_tub_actual_time: new FormControl(),
    });
    bathingcalculationtub.push(bathingcalculationgroup1);
  }
  addtubfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.oneToThreeYear.controls.bathing_tub_frequency.value;


      if (this.isset(frequency)) {
       

        let array = this.getbathingtubarray();
        this.isBathingtub = true;
        this.clearbathingtubcalculation();

        for (let i = 0; i < frequency; i++) {
          this.addbathingtubcalculation();
        }
      }
    }
  }
  getshowertubarray() {
    return this.oneToThreeYear.get('shower_bathing') as FormArray;
  }
  clearshowercalculation() {
    (this.oneToThreeYear.get('shower_bathing') as FormArray).clear();
  }
  addshowercalculation(): void {
    const bathingcalculationtub = this.getshowertubarray();
    const bathingcalculationgroup1 = this.FB.group({
      bathing_shower_time: new FormControl(),
      shower_remark: new FormControl(),
      shower_checkbox: new FormControl(),
      bathing_shower_actual_time: new FormControl(),

    });
    bathingcalculationtub.push(bathingcalculationgroup1);
  }
  addshowerfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency =
        this.oneToThreeYear.controls.bathing_shower_frequency.value;


      if (this.isset(frequency)) {
       

        let array = this.getshowertubarray();
        this.isBathingshower = true;
        this.clearshowercalculation();

        for (let i = 0; i < frequency; i++) {
          this.addshowercalculation();
        }
      }
    }
  }
  getbreastarray() {
    return this.oneToThreeYear.get('breast_feeding') as FormArray;
  }
  clearbreastcalculation() {
    (this.oneToThreeYear.get('breast_feeding') as FormArray).clear();
  }
  addbreastcalculation(): void {
    const breastcalculation = this.getbreastarray();
    const breastcalculationgroup = this.FB.group({
      feeding_breast_time: new FormControl(),
      feeding_breast_checkbox: new FormControl(),
      breast_feeding_remark: new FormControl(),
    });

    breastcalculation.push(breastcalculationgroup);
  }
  addbreastfield() {

    if (!this.isSettingValueProgrammatically) {
     
      let frequency =
        this.oneToThreeYear.controls.feeding_frequency_breast.value;


      if (this.isset(frequency)) {
       

        let array = this.getbreastarray();
        this.isBreast = true;
        this.clearbreastcalculation();

        for (let i = 0; i < frequency; i++) {

          this.addbreastcalculation();
        }
      }
    }
  }
  addsleeptimeObject(string: any, index: any) {
    let vitaminArray = this.getSleepArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_mother: new FormControl(''),
        sleeping_mother_time: new FormControl(''),
        sleeping_mother_actual_time: new FormControl(''),
        sleeping_mother_remark: new FormControl(''),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  addnannyObject(string: any, index: any) {
    let vitaminArray = this.getSleepnannyArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_nanny: new FormControl(''),
        sleeping_nanny_time: new FormControl(''),
        sleeping_nanny_actual_time: new FormControl(''),
        sleeping_nanny_remark: new FormControl()
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  getSleepArray(): FormArray {
    return this.oneToThreeYear.get('sleeping_time') as FormArray;
  }
  getSleepnannyArray(): FormArray {
    return this.oneToThreeYear.get('nanny_time') as FormArray;
  }
  getDiaperChangeControlsfrequency(): FormArray {
    return this.oneToThreeYear.get('diaper_changes') as FormArray;
  }
  clearDiaperControls() {
    (
      this.oneToThreeYear.get('diaper_changes') as FormArray
    ).clear();
  }


  getTimeLabel(index: number): string {
    let changetime = this.getDiaperChangeControlsfrequency();
    let time = new Date(changetime.at(index).get('diaper_label').value);
    return time.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  DiaperChange(event: any) {
    if (!this.isSettingValueProgrammatically) {
      let start = new Date();
      let end = new Date();
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      this.clearDiaperControls();
      let updated = start;

      while (updated < end) {
        if (updated < end) {
          // let time = updated.getHours()+':'+updated.getMinutes();

          let time = new Date();

          time.setHours(updated.getHours());
          time.setMinutes(updated.getMinutes());

          this.addDiaperChange(time);
        }
        updated.setHours(updated.getHours() + event);
      }
    }
  }
  addDiaperChange(time: any): void {
    const CalculationArray = this.getDiaperChangeControlsfrequency();

    const newCalculationGroup = this.FB.group({
      isDiaperChanged: new FormControl(null),
      diaper_label: new FormControl(time),
      diaper_actual_time: new FormControl(time),
      diaperRemark: new FormControl('')

    });
    CalculationArray.push(newCalculationGroup);
  }

  disableForm() {
    const formData: any = this.oneToThreeYear.value;
    for (let key in formData) {
      if ((formData[key] !== null && formData[key] !== ' ') && formData[key] !== undefined) {
        if (Array.isArray(formData[key])) {
          let isenabled = true;
          for (let nestedKey in formData[key]) {
            // if (nestedKey !== '0' && formData[key][nestedKey] !== null && formData[key][nestedKey] !== undefined) {
            const control = this.oneToThreeYear.get(`${key}.${nestedKey}`);
            // if(isenabled == false && (formData[key][nestedKey] == null || formData[key][nestedKey] == "")) {
            if (key == "vitamins_medications_json") {

              for (let innerKey in formData[key][nestedKey]) {
                if (Array.isArray(formData[key][nestedKey][innerKey])) {
                  const innercontrol = this.oneToThreeYear.get(`${key}.${nestedKey}.${innerKey}`);
                  let isenabled1 = true;
                  for (let innergroup in formData[key][nestedKey][innerKey]) {
                    const innercontrol1 = this.oneToThreeYear.get(`${key}.${nestedKey}.${innerKey}.${innergroup}`);
                  
                    if (innercontrol1.get('vitamin_checkbox').value != true && isenabled1 && innercontrol1.get('vitamin_remark').value == null) {
                      if (innercontrol1) {
                        innercontrol1.enable();
                        isenabled1 = false;
                      }
                    }
                    else {
                      if (innercontrol1) {
                        innercontrol1.disable();
                      }
                    }
                  }

                }
              }

            }
            else if(key == "nurse_remarks") {
              const nurseRemarkControl = control.get('nurse_remark');
              const nurseRemarkValue = nurseRemarkControl.value;
          
          
              if (nurseRemarkValue !== null && nurseRemarkValue !== '') {
                  nurseRemarkControl.disable();
              } else {
                  nurseRemarkControl.enable();
              }
          }
            else if (this.checkcontrol(control, ["shower_checkbox", "feeding_breast_checkbox", "feeding_checkbox", "sleeping_mother", "done_sterilization", "isDiaperChanged", "change", "sponge_checkbox", "tub_checkbox", "changing_colthes", "vitamin_checkbox", "regular_meal_type", "regular_meal_lunch", "regular_meal_dinner", "regular_meal_snack", "sleeping_nanny", "done_training_child", "play_child_outside_done", "escort_child_outside_done", "other_child_outside_done","diaper_checkbox"],['breast_feeding_remark','formula_remark','remark','sterilization_remark', 'diaper_remark',  'shower_remark', 'tubBathing_remark', 'clothe_remark', 'vitamin_remark', 'sleeping_nanny_remark', 'diaperRemark','training_child_remark','play_child_outside_remark','escort_child_outside_remark','other_child_outside_remark']) && isenabled) {
              if (control) {
                control.enable();
                isenabled = false;
              }
            }
            else {
              if (control) {
                control.disable();
              }
            }
          }
        } else {
          const control = this.oneToThreeYear.get(key);
          if (control) {
          }
        }
      }
    }
  }

  checkcontrol(control, name,remarks) {
    let a
    for (let i = 0; i < name?.length; i++) {
     
      if (name[i], control.get(name[i])?.value != true && control.get(name[i])?.value != undefined || control.get(name[i])?.value === null) {
        for (let j = 0; j < remarks?.length; j++) {
          if (control.get(remarks[j])?.value != '' && control.get(remarks[j])?.value != undefined) {
            return false;
          }
        }
        return true;
      }
    }
    return false;

  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      // const year = formattedDate.getFullYear();
      // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      // const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
      // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }

  cleardiaperCalculation() {
    (this.oneToThreeYear.get('diaper_calculation') as FormArray).clear();
  }
  getdiaperCalculationControls(): FormArray {
    return this.oneToThreeYear.get('diaper_calculation') as FormArray;
  }
  adddiaperCalculation(time: any): void {
    const feedingCalculationArray = this.getdiaperCalculationControls();

    const newFeedingCalculationGroup = this.FB.group({
      diaper_timeArray: new FormControl(time),
      diaper_actual_time: new FormControl(time),
      remark: new FormControl(null),
      amount: new FormControl(null),
      diaperRemark: new FormControl(null),
      diaper_checkbox: new FormControl(null)
    
    });
    feedingCalculationArray.push(newFeedingCalculationGroup);
  }
  creatediaperfields() {
    if (!this.isSettingValueProgrammatically) {

      let start = new Date(this.oneToThreeYear.controls.diaper_label.value);
      let end = new Date(this.oneToThreeYear.controls.diaper_label_end_time.value);
      let frequency = this.oneToThreeYear.controls.diaper_change_frequency.value;
      // let setbottle_sterilization = new Date(this.oneToThreeYear.controls.diaper_label.value);

    

      if (this.isset(this.oneToThreeYear.controls.diaper_label.value)
        && this.isset(this.oneToThreeYear.controls.diaper_change_frequency.value) &&
        this.isset(frequency)) {
       

        let array = this.getdiaperCalculationControls();
        let updated = start;
        this.isdiaper = true;
        this.cleardiaperCalculation();
        // this.clearbottle_sterilizationControls();

        if (start > end) {
          end.setDate(end.getDate() + 1);
        }

        while (updated < end) {
          if (updated < end) {
            // let time = updated.getHours()+':'+updated.getMinutes();

            let time = new Date();

            time.setHours(updated.getHours())
            time.setMinutes(updated.getMinutes())

            // let sterilization_time = new Date();

            // sterilization_time.setHours(updated.getHours() - 2)
            // sterilization_time.setMinutes(updated.getMinutes())

            this.adddiaperCalculation(time);
            // this.addbottle_sterilization(sterilization_time);
          }
          updated.setHours(updated.getHours() + frequency);
        }
      }
    }
  }
}
